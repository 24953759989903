import { BsSpeedometer, BsCalendar3Range, BsFolder, BsPeople, BsPersonWorkspace, BsCarFront, BsDoorClosed, BsFillPinMapFill, BsPersonCircle } from 'react-icons/bs';
import Sidebar from './Sidebar';

const adminLinks = [
  { to: '/admin/dashboard', icon: <BsSpeedometer className='me-3 fs-5' />, label: 'Dashboard' },
  { to: '/admin/students', icon: <BsPeople className='me-3 fs-5' />, label: 'Students' },
  { to: '/admin/schedules', icon: <BsCalendar3Range className='me-3 fs-5' />, label: 'Schedules' },
  { to: '/admin/instructors', icon: <BsPersonWorkspace className='me-3 fs-5' />, label: 'Instructors' },
  { to: '/admin/vehicles', icon: <BsCarFront className='me-3 fs-5' />, label: 'Vehicles' },
  { to: '/admin/classrooms', icon: <BsDoorClosed className='me-3 fs-5' />, label: 'Classrooms' },
  { to: '/admin/courses', icon: <BsFolder className='me-3 fs-5' />, label: 'Courses' },
  { to: '/admin/branches', icon: <BsFillPinMapFill className='me-3 fs-5' />, label: 'Branches' },
  { to: '/admin/users', icon: <BsPersonCircle className='me-3 fs-5' />, label: 'Users' },
];

const AdminSidebar = () => (
  <Sidebar links={adminLinks} />
);

export default AdminSidebar;