import TableSkeleton from './TableSkeleton';

const Table = ({ headers, rows = [], loading, onRowClick }) => {
  return (
    <div className="table-container">
      {loading ? (
        <TableSkeleton numRows={11} numColumns={headers?.length || 0} />
      ) : (
        <div className="table-responsive">
          <table
            className="table table-striped border border-dark bg-gradient-primary"
            style={{ fontSize: '0.9rem' }}
          >
            <thead>
              <tr>
                {headers?.map((header, index) => (
                  <th
                    key={index}
                    className="bg-gradient-primary text-light border-end text-nowrap pe-4"
                    role="button"
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {Array.isArray(rows) && rows.length > 0 ? (
                rows.map((row, rowIndex) =>
                  Array.isArray(row) ? (
                    <tr
                      key={rowIndex}
                      role="button"
                      onClick={() => onRowClick && onRowClick(row)}
                      className="table-row-hover"
                    >
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className="border-0 text-nowrap">
                          {cell}
                        </td>
                      ))}
                    </tr>
                  ) : (
                    <tr key={rowIndex}>
                      <td colSpan={headers.length} className="text-center">
                        Invalid row data
                      </td>
                    </tr>
                  )
                )
              ) : (
                <tr>
                  <td colSpan={headers?.length || 1} className="text-center">
                    Nothing to show
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default Table;
