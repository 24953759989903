import { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaArrowRight } from "react-icons/fa6";
import { useDocumentTitle, useFetchData, useCrudHandlers } from '../../hooks';
import { ScheduleCalendar } from '../../components/calendar';
import { SessionForm } from '../../components/form';
import { FormModal } from '../../components/modal';
import { Alert, List, LoadingSkeleton } from '../../components/other';


const Schedules = ( props ) => {
  useDocumentTitle("Schedules");
  const [activeItem, setActiveItem] = useState(null);
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [year, setYear] = useState(new Date().getFullYear());
  const [selectedEnrollment, setSelectedEnrollment] = useState(null);
  const [sessionNth, setSessionNth] = useState(null);

  // FETCH DATA
  const { data: pdcAA, error: fetchPdcAAError, refresh: refreshPdcAA, isLoading: isLoadingPdcAA } = useFetchData(
    `enrollments/?status=Awaiting%20Action&course_category_type=PDC&branch=${props.branch}`, true);
  const { data: pdcAFU, error: fetchPdcAFUError, refresh: refreshPdcAFU, isLoading: isLoadingPdcAFU } = useFetchData(
    `enrollments/?status=Awaiting+Follow-Up&course_category_type=PDC&branch=${props.branch}`, true);
  const { data: tdcAA, error: fetchTdcAAError, refresh: refreshTdcAA, isLoading: isLoadingTdcAA } = useFetchData(
    `enrollments/?status=Awaiting%20Action&course_category_type=TDC&branch=${props.branch}`, true);
  const { data: tdcAFU, error: fetchTdcAFUError, refresh: refreshTdcAFU, isLoading: isLoadingTdcAFU } = useFetchData(
    `enrollments/?status=Awaiting+Follow-Up&course_category_type=TDC&branch=${props.branch}`, true);
  const { data: allSessions, error: fetchAllSessionsError, isLoading: isLoadingAllSessions, refresh: refreshAllSessions } = useFetchData(
    `sessions/?page_size=100&month=${month}&year=${year}&branch=${props.branch}&status=Scheduled`, true);

  const isLoading = isLoadingTdcAA || isLoadingTdcAFU || isLoadingPdcAA || isLoadingPdcAFU || isLoadingAllSessions;
  
  const refresh = () => {
    refreshTdcAA();
    refreshTdcAFU();
    refreshPdcAA();
    refreshPdcAFU();
    refreshAllSessions();
  };

  const initialFormData = {
    session_nth: sessionNth,
    session_date: '',
    start_time: '',
    end_time: '',
    enrollment: selectedEnrollment,
    instructor: '',
    facility: '',
    status: '',
  };
  
  // CRUD handlers
  const {
    formData, setFormData, selectedId, setSelectedId, formState, setFormState,
    handleSave, handleArchive, handleModalClose, fetchError,
  } = useCrudHandlers('sessions/', 'sessions/', initialFormData, [refresh], refresh);

  const handleMonthChange = (newMonth, newYear) => {
    setMonth(newMonth);
    setYear(newYear);
  };

  const handleItemClick = (id) => {
    setActiveItem(activeItem === id ? null : id);
  };

  const handleManageClick = (enrollment) => {
    setFormState("new");
    setSelectedEnrollment(enrollment);
    setSessionNth(enrollment.scheduled_sessions + 1);
  };

  if (isLoading) {
    return <LoadingSkeleton />;
  }

  return (
    <div className="row m-0 gap-0 gap-md-4">
      {/* LEFT */}
      <div className="col order-lg-1 order-2 p-0">
        <div className="custom-container p-4">
          {(fetchPdcAFUError) && (
            <Alert message={fetchPdcAFUError} />
          )}
          <ScheduleCalendar 
            sessions={allSessions || []}
            setSelectedId={setSelectedId}
            setSelectedEnrollment={setSelectedEnrollment}
            setFormState={setFormState}
            onMonthChange={handleMonthChange} />
        </div>
      </div>

      {/* RIGHT */}
      <div className="col-lg-3 order-lg-2 order-1 p-0">
        {props.role === "Admin" &&
          <Link to='/admin/sessions'>
            <button className='btn btn-outline-secondary w-100 mb-4 fw-bold rounded-3'>
              SESSIONS TABLE<FaArrowRight className='ms-2'/></button>
          </Link>
        }
        <List
          data={pdcAA}
          handleItemClick={handleItemClick}
          activeItem={activeItem}
          handleManageClick={handleManageClick}
          title="PDC Awaiting Action"
          noDataMessage="You have no enrollments awaiting action at the moment."
        />
        <List
          data={pdcAFU}
          handleItemClick={handleItemClick}
          activeItem={activeItem}
          handleManageClick={handleManageClick}
          title="PDC Awaiting Follow Up"
          noDataMessage="You have no enrollments awaiting follow-up at the moment."
          isDarkMode={false}
        />
        <List
          data={tdcAA}
          handleItemClick={handleItemClick}
          activeItem={activeItem}
          handleManageClick={handleManageClick}
          title="TDC Awaiting Action"
          noDataMessage="You have no TDC enrollments awaiting follow-up at the moment."
        />
        <List
          data={tdcAFU}
          handleItemClick={handleItemClick}
          activeItem={activeItem}
          handleManageClick={handleManageClick}
          title="TDC Awaiting Follow Up"
          noDataMessage="You have no enrollments awaiting follow-up at the moment."
          isDarkMode={false}
        />
        <FormModal
          title={`Session`}
          onClose={handleModalClose}
          isLarge={true}
          setFormState={setFormState}
          formState={formState}
          children={
            <SessionForm
              sessionData={formData}
              enrollmentData={selectedEnrollment}
              formState={formState}
              setFormData={setFormData}
              setSessionNth={setSessionNth}
              sessionNth={sessionNth}
              onChange={(e) => {
                const { name, value } = e.target;
                setFormData(prevData => ({
                  ...prevData,
                  [name]: name === 'facility' && value ? parseInt(value, 10) : value
                }));
              }}
              onSave={handleSave}
            />
          }
        />
      </div>
    </div>
  );
}

export default Schedules;
