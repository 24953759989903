import { useState } from 'react';
import { MdFilterAlt, MdClose } from "react-icons/md";
import { useDocumentTitle, useFetchData, useLoading } from '../../hooks';
import { AreaToBar, BarToBar, PieToBar, ProgressBar, PieStatic } from '../../components/chart';
import { TextInput, SelectInput } from '../../components/input';
import { Table } from '../../components/table';
import { Alert, CardGroup, LoadingSkeleton } from '../../components/other';


const Dashboard = ({ role }) => {
  useDocumentTitle("Dashboard");
  const [ startDate, setStartDate ] = useState('');
  const [ endDate, setEndDate ] = useState('');
  const [ branch, setBranch ] = useState(role === 'Staff' ? localStorage.getItem('branch') : '');
  const [ filtersVisible, setFiltersVisible ] = useState(false);
  const isLoading = useLoading(1000);

  // FETCH DATA with additional query params
  const { data: sessionOTData, loading: sessionOTLoading, error: sessionOTError } = useFetchData(
    `sessions-over-time/?start_date=${startDate}&end_date=${endDate}&branch=${branch}`, [startDate, endDate, branch]
  );
  const { data: sessionData, loading: sessionLoading, error: sessionError } = useFetchData(
    `session-trends/?start_date=${startDate}&end_date=${endDate}&branch=${branch}`, [startDate, endDate, branch]
  );
  const { data: enrollmentData, loading: enrollmentLoading, error: enrollmentError } = useFetchData(
    `enrollment-trends/?start_date=${startDate}&end_date=${endDate}&branch=${branch}`, [startDate, endDate, branch]
  );
  const { data: resourceData, loading: resourceLoading, error: resourceError } = useFetchData(
    `resource-stats/?branch=${branch}`, [branch]
  );
  const { data: instructorData, loading: instructorLoading, error: instructorError } = useFetchData(
    `instructor-utilization/?start_date=${startDate}&end_date=${endDate}&branch=${branch}`, [startDate, endDate, branch]
  );
  const { data: branches, error: fetchBranchesError } = useFetchData('branches/');

  const isError = sessionError || enrollmentError || resourceError || instructorError;

  const { instructors = [], overallUtilizationRate: overallUtilizationRate = 0 } = instructorData || {};

  // TABLE headers and rows
  const headers = ['Instructor Code', 'Instructor Name', 'Hours Assigned', 'Hours Available', 'Utilization Rate', ''];
  const rows = instructors.map(instructor => [
    instructor.instructorCode,
    instructor.instructorName,
    instructor.hoursAssigned,
    instructor.hoursAvailable,
    `${instructor.utilizationRate}%`,
    <ProgressBar key={instructor.instructorCode} data={[{ name: 'Utilization', value: instructor.utilizationRate }]} />
  ]);

  const clearFilters = () => {
    setStartDate('');
    setEndDate('');
    if (role === 'Admin') setBranch('');
  };

  const toggleFilters = () => {
    if (filtersVisible) {
      clearFilters();
      setFiltersVisible(false);
    } else {
      setFiltersVisible(true);
    }
  };

  if (isLoading) {
    return <LoadingSkeleton />;
  }

  
  return (
    <>
    <div className="row m-0">
      <div className="p-0">
        {isError && <Alert message={isError} />}
        {/* COLLLAPSIBLE FILTER */}
        <div className={`custom-container mb-4 pb-3 ${filtersVisible ? 'collapse show' : 'collapse'}`} id="filters">
          <div className="row">
            <div className="col input-group">
              <TextInput
                type="date"
                label="Start Date"
                required={false}
                value={startDate}
                onChange={e => setStartDate(e.target.value)}
              />
              <TextInput
                type="date"
                label="End Date"
                required={false}
                value={endDate}
                onChange={e => setEndDate(e.target.value)}
              />
            </div>
            {role === 'Admin' &&
            <div className="col">
              <SelectInput
                label="Branch"
                name="branch"
                required={false}
                value={branch}
                onChange={(e) => setBranch(e.target.value)}
                options={branches ? branches.results.map(branch => ({
                  value: branch.branch_name,
                  label: branch.branch_name,
                })) : []}
              />
            </div>
            }
          </div>
        </div>

        {/* FOR SMALL SCREEN ONLY */}
        <div className="container p-0">
          <div className="row my-lg-0 mb-4">
            <div className="col d-block d-lg-none">
              <CardGroup cards={[{ text: 'Upcoming Sessions', value: sessionData?.scheduledCount || 0, path: 'schedules' }]} />
            </div>
            <div className="col-auto d-block d-lg-none">
              <button className="btn btn-dark mb-4" type="button" onClick={toggleFilters}>
                {filtersVisible ? <><MdClose className='fs-4'/><br/>Clear</> : <><MdFilterAlt className='fs-4'/><br/>Filter</>}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* DASHBOARD LEFT SIDE */}
      <div className="col p-0">
        <div className="container p-0">
          <CardGroup
            cards={[
              { text: 'Instructors', value: resourceData?.instructorCount || 0, isDarkMode: true, path: 'instructors' },
              { text: 'Vehicles', value: resourceData?.vehicleCount || 0, path: 'vehicles' },
              { text: 'Rooms', value: resourceData?.classroomCount || 0, path: 'classrooms' },
            ]}
          />
        </div>
        <div className="custom-container mt-4">
          <h6>Scheduled Sessions Over Time</h6><br />
          <AreaToBar areaData={sessionOTData?.monthlyStats || []} barData={sessionOTData?.dailyStats || []} />
        </div>
        <div className="custom-container my-4">
          <h6>Course Popularity</h6><br />
          <BarToBar primaryData={enrollmentData?.courseCategoryStats || []} secondaryData={enrollmentData?.courseStats || []} />
        </div>
      </div>
      
      {/* DASHBOARD RIGHT SIDE */}
      <div className="col-lg-4 col ms-lg-4 p-0">
        <div className="row mb-lg-4">
          <div className="col d-none d-lg-block">
            <CardGroup cards={[{ text: 'Upcoming Sessions', value: sessionData?.scheduledCount || 0, path: 'schedules' }]} />
          </div>
          <div className="col-auto d-none d-lg-block">
            <button className="btn btn-primary mb-4" type="button" onClick={toggleFilters}>
            {filtersVisible ? <><MdClose className='fs-4'/><br/>Clear</> : <><MdFilterAlt className='fs-4'/><br/>Filter</>}
            </button>
          </div>
        </div>
        <div className="custom-container bg-gradient-primary mb-4 mt-0 text-light">
          <h6>Session Status Breakdown</h6><br />
          <BarToBar primaryData={sessionData?.statusStats || []} secondaryData={sessionData?.courseCategoryStats || []} />
        </div>
        <div className="custom-container bg-gradient-secondary my-4 mt-lg-0 text-light">
          <h6>Time Preference for PDC</h6><br />
          <PieToBar pieData={sessionData?.timePeriodStats || []} barData={sessionData?.timeRangeStats || []} isDarkMode={true} innerRadius={0} />
        </div>
      </div>

      {/* INSTRUCTOR UTILIZATION REPORT */}
      <div className="custom-container">
        <div className="card m-4">
          <div className="card-header">Reports</div>
          <div className="card-body d-flex flex-column flex-lg-row align-items-center">

            <div className="w-100 w-lg-50 mb-3 mb-lg-0">
              <PieStatic 
                data={[
                  { name: 'Utilization', value: overallUtilizationRate },
                  { name: '', value: 100 - overallUtilizationRate }
                ]} 
              />
            </div>

            <div className="w-100 text-wrap">
              <h5 className="card-title">Instructor Utilization Report</h5>
              <p className="card-text">A summary of how instructors are allocated across scheduled sessions.</p>
              <span>Total Hours Assigned: <strong>{instructorData?.totalHoursAssigned || 0} hours</strong></span><br />
              <span>Total Hours Available: <strong>{instructorData?.totalHoursAvailable || 0} hours</strong></span>
            </div>
          </div>
          
          <div className="card-footer">
            { startDate === "" & endDate === ""
            ? <small>Last 30 Days</small>
            : <small>From {startDate} to {endDate}</small>
            }
          </div>

        </div>
        <div className="px-4">
          <Table headers={headers} rows={rows} />
        </div>

      </div>
    </div>
    </>
  );
}

export default Dashboard;
