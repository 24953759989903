import { useState, useMemo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Header, AdminSidebar, StaffSidebar, Footer } from '.';


const AdminStaffLayout = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const location = useLocation();
  const role = localStorage.getItem('userRole');

  const pageTitle = useMemo(() => {
    const pathname = location.pathname
      .replace(/^\/(admin|staff)\/?/g, '')
      .split('/')[0]
      .replace(/[-_]/g, ' ')
      .toUpperCase();
  
    return pathname;
  }, [location.pathname]);
  
  const toggleSidebar = () => {
    setIsSidebarOpen(prev => !prev);
  };

  return (
    <>
    <div className="container d-flex p-4 min-vh-100">
      {isSidebarOpen && (
        <div className="left-wrapper me-4">
          { role === 'Admin' && <AdminSidebar /> }
          { role === 'Staff' && <StaffSidebar /> }
        </div>
      )}
      <div className={`right-wrapper d-flex flex-column ${!isSidebarOpen ? 'full-width' : ''}`}>
        <Header toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} pageTitle={pageTitle} />
        <Outlet />
      </div>
    </div>

    <Footer/>

    </>
  );
}

export default AdminStaffLayout;
