import { BsSpeedometer, BsCalendar3Range, BsFolder, BsPeople, BsPersonWorkspace, BsCarFront, BsDoorClosed, BsFillPinMapFill, BsPersonCircle } from 'react-icons/bs';
import Sidebar from './Sidebar';

const staffLinks = [
  { to: '/staff/dashboard', icon: <BsSpeedometer className='me-3 fs-5' />, label: 'Dashboard' },
  { to: '/staff/students', icon: <BsPeople className='me-3 fs-5' />, label: 'Students' },
  { to: '/staff/schedules', icon: <BsCalendar3Range className='me-3 fs-5' />, label: 'Schedules' },
  { to: '/staff/instructors', icon: <BsPersonWorkspace className='me-3 fs-5' />, label: 'Instructors' },
  { to: '/staff/vehicles', icon: <BsCarFront className='me-3 fs-5' />, label: 'Vehicles' },
  { to: '/staff/classrooms', icon: <BsDoorClosed className='me-3 fs-5' />, label: 'Classrooms' },
  { to: '/staff/courses', icon: <BsFolder className='me-3 fs-5' />, label: 'Courses' },
];

const StaffSidebar = () => (
  <Sidebar links={staffLinks} />
);

export default StaffSidebar;