import { Schedules } from "../shared";


const branch = localStorage.getItem('branch');

const StaffSchedules = () => {
  return <Schedules role='Staff' branch={branch} />
}

export default StaffSchedules;
