import { useState } from 'react';
import { useDocumentTitle, useFetchData, useCrudHandlers, useLoading } from '../../hooks';
import { SessionForm } from '../../components/form';
import { FormModal } from '../../components/modal';
import { TableHeader, DataTable, Pagination } from '../../components/table';
import { Alert } from '../../components/other';


const Branches = () => {
  useDocumentTitle("Schedules");
  const itemsPerPage = 10;
  const loading = useLoading(1000);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [filters, setFilters] = useState({ status: '', date: '', startTime: '', endTime: '' });
  const [sortField, setSortField] = useState('session_date');
  const [sortOrder, setSortOrder] = useState('desc');
  const [selectedEnrollment, setSelectedEnrollment] = useState(null);
  const [sessionNth, setSessionNth] = useState(null);

  // FETCH DATA with additional query params
  const { data: sessions, error: fetchSessionsError, totalItems, isLoading: isLoadingSessions, refresh } = useFetchData(
    `sessions/?page=${currentPage}&page_size=${itemsPerPage}&search=${searchQuery}&branch=${filters.status}&session_date=${filters.date}&start_time=${filters.startTime}&end_time=${filters.endTime}&ordering=${sortOrder === 'asc' ? '' : '-'}${sortField}`,
    true, [searchQuery, filters, sortField, sortOrder, currentPage]
  );

  // FETCH DATA for options
  const { data: branches, error: fetchBranchesError} = useFetchData('valid-branches/');

  const initialFormData = {
    session_nth: sessionNth,
    session_date: '',
    start_time: '',
    end_time: '',
    enrollment: selectedEnrollment,
    instructor: '',
    facility: '',
    status: '',
  };

  // CRUD handlers
  const {
    formData, setFormData, selectedId, setSelectedId, formState, setFormState,
    handleSave, handleArchive, handleModalClose, fetchError,
  } = useCrudHandlers('sessions/', 'sessions/', initialFormData, [refresh], refresh);

  // TABLE headers and rows
  const headers = ['Session Date', 'Student Code', 'Course', 'Instructor', 'Facility', 'Status'];
  const rows = sessions ? sessions.map(session => [
    session.session_date, session.student_code, session.course_name,
    session.instructor_name, session.facility_name, session.status
  ]) : [];
  

  return (
    <div className="custom-container">
      <TableHeader
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        filterOptions={ branches ? [{ value: "", label: "..." }, ...branches.results.map(branch => ({
          value: branch.branch_name, label: branch.branch_name, }))] : [] }
        setFilters={setFilters}
        setSortField={setSortField}
        setSortOrder={setSortOrder}
      />
      {fetchSessionsError && <Alert message={fetchSessionsError} />}
      <DataTable
        headers={headers}
        rows={rows}
        loading={isLoadingSessions}
        primaryKeyIndex={0}
        setSelectedId={setSelectedId}
        setSelectedEnrollment={setSelectedEnrollment}
        setFormState={setFormState}
        handleArchive={handleArchive}
        setSortField={setSortField}
        setSortOrder={setSortOrder}
      />
      <Pagination
        setCurrentPage={setCurrentPage}
        totalItems={totalItems}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
      />
      {/* <FormModal
        title={formState === 'new' ? 'New Branch' : formState === 'edit' ? `Edit ${selectedId} Branch` : `${selectedId} Branch`}
        open={selectedId !== null}
        onClose={handleModalClose}
      >
        <BranchForm
          branchData={formData}
          formState={formState}
          onChange={(e) => setFormData(prevData => ({ ...prevData, [e.target.name]: e.target.value }))}
          onReset={handleModalClose}
          onSave={handleSave}
        />
      </FormModal> */}
      <FormModal
        title={`Session`}
        onClose={handleModalClose}
        isLarge={true}
        setFormState={setFormState}
        formState={formState}
        children={
          <SessionForm
            sessionData={formData}
            enrollmentData={selectedEnrollment}
            formState={formState}
            setFormData={setFormData}
            setSessionNth={setSessionNth}
            sessionNth={sessionNth}
            onChange={(e) => {
              const { name, value } = e.target;
              setFormData(prevData => ({
                ...prevData,
                [name]: name === 'facility' && value ? parseInt(value, 10) : value
              }));
            }}
            onSave={handleSave}
          />
        }
      />
    </div>
  );
};

export default Branches;