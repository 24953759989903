import { SiFacebook, SiMessenger, SiGmail } from 'react-icons/si';


const Footer = () => {
  return (
    <div className="container-fluid bg-gradient-primary w-100">
      <footer className="container d-flex flex-wrap justify-content-between align-items-center py-3">
        <div className="col-md-4 d-flex align-items-center">
          <span className="mb-3 mb-md-0 text-light">&copy; Top Drive Driving Institute</span>
        </div>

        <ul className="nav col-md-4 justify-content-end list-unstyled d-flex">
          <li className="ms-3">
            <a className="text-light" href="#">
              <SiFacebook/>
            </a>
          </li>
          <li className="ms-3">
            <a className="text-light" href="#">
              <SiMessenger/>
            </a>
          </li>
          <li className="ms-3">
            <a className="text-light" href="#">
              <SiGmail/>
            </a>
          </li>
        </ul>
      </footer>
    </div>
  );
}

export default Footer;