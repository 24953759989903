import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { MdAdd, MdFilterAlt, MdClose } from "react-icons/md";
import { BsSearch } from "react-icons/bs";

function TableHeader({ searchQuery, setSearchQuery, filterOptions, setFilters, setFilterStatus, adminOnly = true }) {
  const navigate = useNavigate();
  const location = useLocation();
  const role = localStorage.getItem('userRole');
  const isSessionsPage = location.pathname === '/admin/sessions';
  const permitted = localStorage.getItem('canEdit') === 'true';
  const [showFilter, setShowFilter] = useState(false);
  

  // UPDATE URL with debounce
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const params = new URLSearchParams(location.search);
      searchQuery ? params.set('search', searchQuery) : params.delete('search');
      navigate({ search: params.toString() });
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchQuery, location.search, navigate]);

  // FILTER
  const handleFiltersChange = (value, key) => {
    setFilters((prev) => ({ ...prev, [key]: value }));

    const params = new URLSearchParams(location.search);
    value ? params.set(key, value) : params.delete(key);
    navigate({ search: params.toString() });
  };

  const handleFilterChange = (e) => {
    const selected = e.target.value;
    setFilterStatus(selected);
    const params = new URLSearchParams(location.search);
    params.set('filter', selected);
    navigate({ search: params.toString() });
  };

  // CLEAR FILTER
  const handleClearFilter = () => {
    setFilters({ status: '', date: '', startTime: '', endTime: '' });

    const params = new URLSearchParams(location.search);
    ['status', 'date', 'startTime', 'endTime'].forEach((key) => params.delete(key));
    navigate({ search: params.toString() });
  };
  
  // TOGGLE FILTER
  const toggleFilters = () => {
    if (showFilter) handleClearFilter();
    setShowFilter(!showFilter);
  };


  return (
    <>
      <div className="row justify-content-between gap-3">
        <form className="col" role="search" onSubmit={(e) => e.preventDefault()}>
          <div className="input-group">
            <input type="search"
              className="form-control border-secondary"
              placeholder="Search"
              aria-label="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <button className="btn btn-secondary text-light" type="button">
              <BsSearch />
            </button>
          </div>
        </form>

        <div className="col d-flex flex-wrap justify-content-end gap-3">
          <div className="">
            {role === 'Admin' && setFilters && (
              <button type="button" className="btn btn-outline-secondary d-flex align-items-center gap-1"
                onClick={toggleFilters}
              >
                {showFilter
                  ? ( <><span className="d-none d-lg-inline">Clear</span><MdClose className="fs-5" /></>)
                  : ( <><span className="d-none d-lg-inline">Filter</span><MdFilterAlt className="fs-5" /></> )}
              </button>
            )}
            {role === 'Admin' && setFilterStatus && (
              <div className="input-group">
                <select className="form-select border border-secondary text-secondary"
                  onChange={handleFilterChange}
                >
                  {filterOptions.map((option) => (
                    <option key={option.value} value={option.value}>{option.label}</option>
                  ))}
                </select>
                <button className="btn btn-outline-secondary d-flex align-items-center gap-1" type="button"><MdFilterAlt className="fs-5" /></button>
              </div>
            )}
          </div>
            {!isSessionsPage && permitted && (!adminOnly || role === 'Admin') && (
              <button type="button"
                className="btn btn-secondary text-light d-flex align-items-center gap-1"
                data-bs-toggle="modal" data-bs-target="#form-modal"
              >
                <span className="d-none d-lg-inline">New</span>
                <MdAdd className="fs-5" />
              </button>
            )}
        </div>
      </div>

      {showFilter && (
        <div className="mt-3">
          <div className="input-group d-flex flex-row align-items-start">
            <select style={{ minWidth: '100px' }}
              className="form-select border border-secondary text-secondary"
              onChange={(e) => handleFiltersChange(e.target.value, 'status')}
            >
              {filterOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            <input type="date"
              className="form-control border border-secondary text-secondary"
              onChange={(e) => handleFiltersChange(e.target.value, 'date')}
            />
            <input type="time"
              className="form-control border border-secondary text-secondary"
              onChange={(e) => handleFiltersChange(e.target.value, 'startTime')}
            />
            <input type="time"
              className="form-control border border-secondary text-secondary"
              onChange={(e) => handleFiltersChange(e.target.value, 'endTime')}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default TableHeader;
