import { Link } from 'react-router-dom';
import { MdMenuBook, MdDirectionsCar, MdOutlineLaptopMac, MdChevronRight } from 'react-icons/md';
import { FaMotorcycle } from "react-icons/fa6";
import { useDocumentTitle, useResponsiveHeight } from '../../hooks';
import carInterior from '../../assets/images/interior_car.jpg';


const Home = () => {
  useDocumentTitle("Home");
  const minHeight = useResponsiveHeight();
  

  return (
    <>
      {/* COVER */}
      <div className="container d-flex flex-column" style={{ minHeight: minHeight }}>
        <div className="d-flex flex-grow-1 align-items-start align-items-md-center">
          <div className="container">
            <div className="row justify-content-center align-items-center text-center text-md-start mb-4">
              <div className="col-md-6 mb-4">
                <h1 className="display-3 text-light">Be on Top.<br />Start with Us!</h1>
                <h6 className="lead text-white-50 my-4">
                  Top Drive Driving Institute - Your Driving 101 Masters
                </h6>
                {localStorage.getItem('loginStatus') === true && (
                  <Link to="/login" className="btn btn-secondary fw-bold me-3">Log In</Link>
                )}
                <Link to="/about" className="btn btn-outline-primary">Learn More</Link>
              </div>
              <div className="col-md-6 py-4 position-relative">
                <div className="d-none d-xxl-block shape-decor"></div>
                <img
                  src={carInterior}
                  alt="Driving Illustration"
                  className="position-relative img-thumbnail border border-5 border-primary rounded-5"
                  style={{ zIndex: 1 }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* FEATURED COURSES */}
      <div className="bg-primary-light d-flex align-items-center" style={{ minHeight: '85vh' }}>
        <div className="container text-center text-md-start">
          <h1 className="border-bottom p-4">Featured Courses</h1>
          <div className="row g-5 p-4">
            <div className="col-12 col-md-6 d-flex flex-column gap-4 align-items-center align-items-md-start justify-content-center">
              <h2 className="fw-bold text-dark text-center text-md-start pe-lg-4">
                We offer various driving courses tailored to both theoretical and practical skills.
              </h2>
              <p className="text-body-secondary text-center text-md-start pe-lg-4">
                Whether you're a beginner or looking to refine your driving abilities, our expert instructors provide lessons to ensure safe and confident driving.
              </p>
              <Link to="/course-details" className="btn btn-outline-secondary fw-bold">
                View All <MdChevronRight />
              </Link>
            </div>


            <div className="col">
              <div className="row row-cols-1 row-cols-sm-2 g-4">
                {[ 
                  { icon: <MdDirectionsCar />, title: '4 Wheel Courses', desc: 'Master both manual and automatic transmissions.' },
                  { icon: <FaMotorcycle />, title: '2/3 Wheel Courses', desc: 'Courses for motorcycle and scooter enthusiasts.' },
                  { icon: <MdOutlineLaptopMac />, title: 'Special Courses', desc: 'Tailored courses for unique driving needs.' },
                  { icon: <MdMenuBook />, title: 'Theoretical Driving Courses', desc: 'Learn the essential theory behind safe driving.' },
                ].map((course, index) => (
                  <div className="col d-flex flex-column gap-3 align-items-center align-items-md-start" key={index}>
                    <div className="feature-icon d-inline-flex align-items-center justify-content-center text-bg-primary text-dark fs-2 rounded-3">
                      {course.icon}
                    </div>
                    <h4 className="fw-semibold mb-0">{course.title}</h4>
                    <p className="text-body-secondary">{course.desc}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
