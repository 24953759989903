import { useState, useEffect } from 'react';
import { useLocation, Outlet } from 'react-router-dom';
import { TopNavbar, Footer } from '.';


const PublicLayout = () => {
  const [loginStatus, setLoginStatus] = useState(localStorage.getItem('loginStatus') === "true");
  const [userRole, setUserRole] = useState(localStorage.getItem('userRole'));

  useEffect(() => {
    const handleStorageChange = () => {
      setLoginStatus(localStorage.getItem('loginStatus') === "true");
      setUserRole(localStorage.getItem('userRole'));
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const location = useLocation();
  
  const isAbout = location.pathname === '/about';
  const isProfile = location.pathname === '/student-profile' || location.pathname === '/instructor-profile';
  const isSchedule = location.pathname === '/student-schedule' || location.pathname === '/instructor-schedule';
  const isLogin = location.pathname === '/login';
  const isRegistration = location.pathname === '/register';
  const backgroundMode = isAbout || isProfile || isSchedule || isLogin || isRegistration ? 'bg-primary-light' : 'bg-gradient-primary';
  const textMode = isAbout || isProfile || isSchedule || isLogin || isRegistration ? 'text-dark' : 'text-light';
  
  
  return (
    <div className={`LandingPage ${backgroundMode}`}>
      {!isLogin && !isRegistration && (
        <TopNavbar 
          textMode={textMode} 
          loginStatus={loginStatus}
          userRole={userRole}
        />
      )}
      <Outlet />
      {!isLogin && !isRegistration && <Footer />}
    </div>
  );
}

export default PublicLayout;
