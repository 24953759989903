import { Link } from 'react-router-dom';

const CardGroup = ({ cards }) => {
  const basePath = localStorage.getItem('userRole')?.toLowerCase();

  return (
    <div className="card-group shadow">
      {cards.map(({ text, value, isDarkMode = false, path = '/' }, index) => {
        const cardClass = isDarkMode
          ? 'bg-dark text-light'
          : 'bg-light';
        
        const borderClass = index < cards.length - 1 ? 'border-end border-2' : '';

        return (
          <Link
            key={text + index}
            to={`/${basePath}/${path}`}
            className={`card ${cardClass} ${borderClass} p-3`}
            style={{ textDecoration: 'none', color: 'inherit', cursor: 'pointer' }}
          >
            <div className="card-body">
              <span className="card-text fs-6">{text}</span>
              <h5 className="card-title">{value !== undefined ? value : 0}</h5>
            </div>
          </Link>
        );
      })}
    </div>
  );
};

export default CardGroup;
