import { useState, useEffect } from 'react';
import { useFetchData } from '../../hooks';
import { TextInput, SelectInput, MarkedDatePicker } from '../input';
import { PieStatic } from '../chart';
import { Table } from '../table';


const SessionForm = ({ sessionData, setFormData, formState='view', enrollmentData, setSessionNth, sessionNth, onChange, onSave }) => {
  const [enrollment, setEnrollment] = useState(null);
  const [instructors, setInstructors] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [classrooms, setClassrooms] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [showTable, setShowTable] = useState(false);

  const isDateTodayOrPast = (date) => {
    const sessionDate = new Date(date);
    const today = new Date();

    sessionDate.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);

    return sessionDate <= today;
  };

  const isTdc = enrollment?.course_category === 'Theoretical Driving Course';
  const progress = Math.round((enrollment?.completed_sessions || 0) / (enrollment?.total_sessions || 1) * 100);

  // FETCH DATA
  const { data: schedules, error: scheduleError, isLoading: scheduleLoading } = useFetchData(
    sessionData?.session_nth && enrollment?.branch
      ? `tdc-schedule-list/?session_nth=${sessionData?.session_nth || ''}&branch=${enrollment?.branch || ''}`
      : null, [sessionNth, refresh]
  );
  const { data: match } = useFetchData(
    sessionData?.session_nth && sessionData?.session_date && sessionData?.start_time && sessionData?.end_time && enrollment?.branch
      ? `tdc-schedule-match/?session_nth=${sessionData?.session_nth || ''}&session_date=${sessionData?.session_date || ''}&start_time=${sessionData?.start_time}&end_time=${sessionData?.end_time}&branch=${enrollment?.branch || ''}`
      : null, [sessionData?.session_nth, sessionData?.session_date, sessionData?.start_time, sessionData?.end_time, refresh]
  );
  const { data: recommendations } = useFetchData(
    enrollment?.enrollment_id && sessionData?.session_nth && sessionData?.session_date && sessionData?.start_time && sessionData?.end_time && enrollment?.branch
      ? `schedule-recommendation/?enrollment_id=${enrollment?.enrollment_id || ''}&session_nth=${sessionData?.session_nth || ''}&session_date=${sessionData?.session_date || ''}&start_time=${sessionData?.start_time || ''}&end_time=${sessionData?.end_time || ''}&branch=${enrollment?.branch || ''}`
      : null, [sessionData?.session_nth, sessionData?.session_date, sessionData?.start_time, sessionData?.end_time, refresh]
  );
  
  // TABLE headers and rows
  const headers = ['Date', 'Duration', 'Classroom', 'Instructor', 'Slots', 'Is Preferred'];
  const rows = schedules?.map(schedule => [
    schedule?.sessionDate,
    schedule?.startTime + ' - ' + schedule?.startTime,
    schedule?.classroom,
    schedule?.instructor,
    schedule?.availableSlots,
    schedule?.isPreferred ? 'Yes' : 'No',
  ]);  

  useEffect(() => {
    if (isTdc && match?.hasMatch === true) {
      setFormData(prev => ({
        ...prev,
        instructor: match.matches[0]?.instructorCode,
        facility: match?.matches?.[0]?.facilityId,
        status: 'Scheduled',
      }));
    }
  }, [isTdc, match?.hasMatch, match?.matches]); 

  useEffect(() => {
    if (recommendations) {
      setInstructors(recommendations.instructors || []);
      setVehicles(recommendations.vehicles || []);
      setClassrooms(recommendations.classrooms || []);
    }
  }, [recommendations]);

  useEffect(() => {
    if (enrollmentData) {
      setEnrollment(enrollmentData);
      setSessionNth(enrollmentData.scheduled_sessions + 1);
      setFormData(prev => ({
        ...prev,
        enrollment: enrollmentData.enrollment_id,
        session_nth: sessionNth,
      }));
    }
  }, [enrollmentData, sessionNth, setFormData]);

  const handleDateChange = (date) => {
    setFormData(prev => ({ ...prev, session_date: date.format('YYYY-MM-DD') }));
    setRefresh(prev => !prev);
  };

  const removeDuplicateOptions = (options) => {
    const seen = new Set();
    return options.filter(option => {
      if (seen.has(option.value)) {
        return false;
      }
      seen.add(option.value);
      return true;
    });
  };
  
  if (!enrollment) return <div>Loading...</div>;
  
  return (
    <form>
      <div className='mb-3 p-4 pb-2 border border-4 border-primary rounded'>
        <div className='card-body d-flex flex-column flex-lg-row align-items-center h'>
          <div className='order-1 order-md-1 me-3 w-100 mb-3 mb-md-0'>
            <PieStatic data={[{ name: 'Utilization', value: progress }, { name: '', value: 100 - progress }]} label='Progress' />
          </div>
          <div className='order-2 order-md-2 w-100 lh-1'>
            <p><span className='fw-bold'>Student ID:</span> {enrollment?.student}</p>
            <p><span className='fw-bold'>Name:</span> {enrollment?.student_name}</p>
            <p><span className='fw-bold'>Branch:</span> {enrollment?.branch}</p>
            <p><span className='fw-bold'>Duration:</span> {enrollment?.total_hours} hours</p>
            {<p><span className='fw-bold'>Scheduled:</span> {enrollment?.scheduled_sessions} out of {enrollment?.total_sessions} sessions</p>}
            {<p><span className='fw-bold'>Completed:</span> {enrollment?.completed_sessions} out of {enrollment?.total_sessions} sessions</p>}
            <p><span className='fw-bold'>Enrollment Status:</span> {enrollment?.status}</p>
            {enrollment?.remarks && <p><span className='fw-bold'>Remarks:</span> {enrollment?.remarks}</p>}
            {isTdc && formState !== 'view' && 
            <button
              onClick={(event) => {
              event.preventDefault();
              setShowTable(prev => !prev);
              }} className='w-100 btn btn-sm btn-outline-secondary mb-3'>
              {showTable ? 'Hide Recommended Slots' : 'View Recommended Slots'}
            </button>}
          </div>
        </div>
      </div>

      <div className='session-form mb-3 p-4 pb-2 border border-4 rounded'>
        <div className='row gx-3 gy-lg-3 align-items-center'>
          <div className='col-6 col-lg-3'>
            <TextInput
              type='text'
              label='Session Nth'
              name='session_nth'
              value={formState === 'new' ? sessionNth : sessionData.session_nth}
              disabled
            />
          </div>
          <div className='col-6 col-lg-3'>
            <MarkedDatePicker
              label='Session Date'
              name='session_date'
              value={sessionData.session_date}
              preferredDates={enrollment?.preferred_dates || []}
              onChange={handleDateChange}
              disabled={formState === 'view'}
            />
          </div>
          <div className='col-6 col-lg-3'>
            <TextInput
              label='Start Time'
              name='start_time'
              type='time'
              value={sessionData?.start_time || ''}
              onChange={onChange}
              disabled={formState === 'view'}
            />
          </div>
          <div className='col-6 col-lg-3'>
            <TextInput
              label='End Time'
              name='end_time'
              type='time'
              value={sessionData?.end_time || ''}
              onChange={onChange}
              disabled={formState === 'view'}
            />
          </div>
        </div>

        <div className='row gx-3 gy-lg-3 align-items-center'>
          <div className='col-12 col-lg-4'>
            <SelectInput
              label="Instructor"
              name="instructor"
              value={sessionData?.instructor || ""}
              onChange={onChange}
              options={removeDuplicateOptions([
                ...instructors?.map(instructor => ({ value: instructor.instructorCode, label: instructor.instructorName })),
                ...(match?.hasMatch ? [{ value: match.matches[0]?.instructorCode, label: match.matches[0]?.instructor }] : []),
                ...(formState !== "new" ? [{ value: sessionData?.instructor, label: sessionData?.instructor_name }] : []),
              ])}
              disabled={formState === "view"}
            />

          </div>
          {enrollment.transmission_type === 'NA'
          ? <div className='col-12 col-lg-4'>
            <SelectInput
              label='Classroom'
              name='facility'
              value={sessionData?.facility || ''}
              onChange={onChange}
              options={removeDuplicateOptions([
                ...classrooms?.map(classroom => ({ value: classroom.facilityId, label: classroom.classroomName })),
                ...(match?.hasMatch  ? [{ value: match.matches[0]?.facilityId, label: match.matches[0]?.classroom }] : []),
                ...(formState !== 'new' ? [{ value: sessionData?.facility, label: sessionData?.facility_name }] : []),
              ])}
              disabled={formState === 'view'}
            />
          </div>
          : <div className='col-12 col-lg-4'>
          <SelectInput
            label='Vehicle'
            name='facility'
            value={sessionData?.facility || ''}
            onChange={onChange}
            options={removeDuplicateOptions([
              ...vehicles.map(vehicle => ({ value: vehicle.facilityId, label: vehicle.vehicleName })),
              ...(match?.hasMatch ? [{ value: match.matches[0]?.facilityId, label: match.matches[0]?.vehicle }] : []),
              ...(formState !== 'new' ? [{ value: sessionData?.facility, label: sessionData?.facility_name }] : []), 
            ])}
            disabled={formState === 'view'}
          />
          </div>
          }
          <div className='col-12 col-lg-4'>
            <SelectInput
              label='Session Status'
              name='status'
              value={sessionData?.status || ''}
              onChange={onChange}
              options={[
                { value: 'Scheduled', label: 'Scheduled' },
                isDateTodayOrPast && { value: 'Completed', label: 'Completed' },
                { value: 'Missed', label: 'Missed' },
                { value: 'Cancelled', label: 'Cancelled' },
              ]}
              disabled={formState === 'view'}
            />
          </div>
        </div>
      </div>
      
      {isTdc && showTable && formState !== 'view' && <Table headers={headers} rows={rows} />}

      <div className='modal-footer pe-0'>
        {formState !== 'view' && (
          <button className='btn btn-primary' type='button' onClick={onSave} data-bs-dismiss='modal'>
            Save
          </button>
        )}
      </div>
    </form>
  );
};

export default SessionForm;
