import { useState } from 'react';


const TextAreaInput = ({ label, name, value, onChange, error, disabled, required = false }) => {
  const [requiredError, setRequiredError] = useState("");

  const handleBlur = () => {
    if (required && !value) {
      setRequiredError("This field is required.");
    } else {
      setRequiredError("");
    }
  };

  return (
    <div className="form-floating mb-3">
      <textarea
        name={name}
        value={value}
        onChange={onChange}
        onBlur={handleBlur}
        className={`form-control border-dark ${error || requiredError ? 'is-invalid' : ''}`}
        disabled={disabled}
        required={required}
        style={{ height: "100px" }}
      ></textarea>
      <label htmlFor={name} className="text-dark">{label}</label>
      {(error || requiredError) && <div className="invalid-feedback">{requiredError || error}</div>}
    </div>
  );
};

export default TextAreaInput;