import { useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const PasswordInput = ({ label, name, value, onChange, error, disabled, required = true, validateStrength = true }) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [requiredError, setRequiredError] = useState("");

  const passwordValidationRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&_])[A-Za-z\d@$!%*?&_]{8,}$/;

  const handleBlur = () => {
    if (required && !value) {
      setRequiredError("This field is required.");
    } else {
      setRequiredError("");
    }

    if (validateStrength && value && !passwordValidationRegex.test(value)) {
      setPasswordError("Password must be at least 8 characters long, contain one uppercase letter, one lowercase letter, one number, and one special character.");
    } else {
      setPasswordError("");
    }
  };

  return (
    <div className="input-group mb-3">
      <div className="form-floating is-invalid">
        <input 
          type={passwordVisible ? 'text' : 'password'}
          name={name}
          value={value}
          onChange={onChange}
          onBlur={handleBlur}
          className={`form-control border-dark rounded-end-0 ${error || passwordError || requiredError ? 'is-invalid' : ''}`}
          aria-invalid={error || passwordError || requiredError ? 'true' : 'false'}
          disabled={disabled}
          required={required}
        />
        <label htmlFor={name} className='text-dark'>{label}</label>
      </div>
      <button
        type="button"
        className="input-group-text btn btn-outline-dark"
        onClick={() => setPasswordVisible(!passwordVisible)}
        tabIndex="-1"
      >
        {passwordVisible ? <FaEye /> : <FaEyeSlash />}
      </button>
      {(error || passwordError || requiredError) && <div className="invalid-feedback">{requiredError || passwordError || error}</div>}
    </div>
  );
};

export default PasswordInput;
