import { useState } from 'react';
import { AreaChart, Area, XAxis, YAxis, Tooltip, BarChart, Bar, ResponsiveContainer, CartesianGrid } from 'recharts';
import { MdOutlineArrowBack } from "react-icons/md";
import { Alert } from '../other';


const AreaToBar = ({ areaData, barData, areaColors = '#F7C251' }) => {
  const [selectedGroup, setSelectedGroup] = useState(null);

  const handleAreaClick = (entry) => {
    if (entry?.activeLabel) {
      setSelectedGroup(entry.activeLabel);
    }
  };

  const handleBackClick = () => {
    setSelectedGroup(null);
  };

  if (!areaData || areaData.length === 0) {
    return <Alert message={"Nothing to show"} />;
  }

  
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between'}}>
      {selectedGroup && (
        <div className="text-center">
          <button onClick={handleBackClick} className="btn btn-outline-dark">
            <MdOutlineArrowBack />
          </button>
        </div>
      )}
      
      <ResponsiveContainer width={"100%"} height={200} margin={0}>
        {selectedGroup ? (
          <BarChart data={barData[selectedGroup]}>
            <XAxis dataKey="day" fontSize={14}/>
            <YAxis fontSize={14} />
            <Bar dataKey="count" fill="#F0A500" stroke="#211F1F" label={{ fill: '#F5F5F5', fontSize: 12 }} />
          </BarChart>
        ) : (
          <AreaChart data={areaData} onClick={handleAreaClick} margin={0}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" tick={{ fontSize: 14 }} />
            <YAxis tick={{ fontSize: 14 }} />
            <Tooltip formatter={(count) => [count, 'Total']} contentStyle={{ fontSize: '14px' }} />
            <Area type="monotone" dataKey="count" stroke="#F0A500" strokeWidth={2} fill={areaColors} />
          </AreaChart>
        )}
      </ResponsiveContainer>
    </div>
  );
};

export default AreaToBar;