import { useState, useEffect } from 'react';

const useResponsiveHeight = () => {
  const [minHeight, setMinHeight] = useState(window.innerWidth >= 992 ? '85vh' : 'auto');

  useEffect(() => {
    const handleResize = () => {
      setMinHeight(window.innerWidth >= 992 ? '85vh' : 'auto');
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return minHeight;
};

export default useResponsiveHeight;
