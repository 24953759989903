import { useState } from "react";


const List = ({ data, activeItem, handleItemClick, handleManageClick, title, noDataMessage, isDarkMode = true }) => {
  
  const userRole = localStorage.getItem('userRole');
  const canEdit = localStorage.getItem('canEdit');
  const [isCollapsed, setIsCollapsed] = useState(true);
  const toggleCollapse = () => setIsCollapsed((prev) => !prev);


  return (
    <div className={`custom-container text-wrap p-4 mb-4 ${isDarkMode ? "bg-gradient-primary text-light" : "bg-light border border-dark border-2"}`}>
      <div className="card-header">
        <div className="d-flex justify-content-between align-items-center">
          <h6>{`${title} (${data?.length || 0})`}</h6>
          <button className={`btn btn-sm ${isDarkMode ? "btn-outline-primary" : "btn-outline-secondary"}`}
            onClick={toggleCollapse} aria-expanded={!isCollapsed}
          >
            {isCollapsed ? "Show" : "Hide"}
          </button>
        </div>
        <hr />
      </div>

      <div className={`collapse ${isCollapsed ? "" : "show"}`}>
        <ul className="list-group d-grid border-0 gap-3">
          {data?.length === 0 ? (
            <li className="list-group-item text-center">
              <small>{noDataMessage}</small>
            </li>
          ) : (
            data?.map((enrollment) => (
              <li
                key={enrollment.enrollment_id}
                className={`list-group-item rounded-3 py-3 bg-light ${!isDarkMode && 'border border-2 border-primary'}`}
                onClick={() => handleItemClick(enrollment.enrollment_id)}
                style={{ cursor: "pointer" }}
              >
                <div className="d-flex justify-content-between align-items-start">
                  <div>
                    <span className="fw-bold">{enrollment.student}</span>
                    <br />
                    <small>
                      {enrollment.transmission_type !== "NA"
                        ? `${enrollment.transmission_type}-`
                        : ""}
                      {enrollment.course}
                    </small>
                  </div>
                  {canEdit === 'true' &&
                    <button
                      className="btn btn-sm btn-secondary text-light"
                      data-bs-toggle="modal"
                      data-bs-target="#form-modal"
                      onClick={(event) => {
                        event.stopPropagation();
                        handleManageClick(enrollment);
                      }}
                    >
                      Manage
                    </button>
                  }
                </div>

                {/* ACTIVE ITEM DETAILS */}
                {activeItem === enrollment.enrollment_id && (
                  <div className="details mt-2">
                    <hr />
                    <small>Branch: {enrollment.branch_name}</small>
                    <br />
                    <small>Name: {enrollment.student_name}</small>
                    <br />
                    <small>Duration: {enrollment.total_hours} hours</small>
                  </div>
                )}
              </li>
            ))
          )}
        </ul>
      </div>
    </div>
  );
};

export default List;
