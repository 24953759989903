import { cloneElement } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { FaCheck } from "react-icons/fa6";
import { CalendarToolbar } from '../calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';


const localizer = momentLocalizer(moment);

// STYLE DATE CELLS
const ColoredDateCellWrapper = ({ children, value }) => {
  const isToday = moment(value).isSame(new Date(), 'day');
  const backgroundColor = isToday ? '#FDE3A7' : 'transparent';

  return cloneElement(children, {
    style: {
      ...children.style,
      backgroundColor,
    },
  });
};

const EventComponent = ({ event }) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <span>{event.title}</span>
    {event.icon && <span style={{ marginLeft: '8px' }}>{event.icon}</span>} 
  </div>
);

const MyCalendar = ({ sessions, setSelectedSession }) => {
  const events = sessions?.map(session => ({
    id: session.session_id,
    title: `${session.transmission_type} - ${session.course}`,
    transmission_type: `${session.transmission_type}`,
    nth: `${session.session_nth}`,
    status: `${session.status}`,
    enrollment: `${session.course_name}`,
    date: `${session.session_date}`,
    time: `${session.start_time} to ${session.end_time}`,
    branch: `${session.branch}`,
    start: new Date(`${session.session_date}T${session.start_time}`),
    end: new Date(`${session.session_date}T${session.end_time}`),
    icon: session.status === 'Completed' ? <FaCheck /> : null,
  }));

  // STYLE EVENTS
  const eventStyleGetter = () => ({
    style: {
      backgroundColor: '#FDE3A7',
      border: '3px solid #F8D27F',
      borderRadius: '3px',
      opacity: 1,
      color: 'black',
      display: 'block',
      fontSize: '0.85rem',
    },
  });

  // STYLE DAY CELL
  const dayPropGetter = (date) => ({
    style: {
      backgroundColor: moment(date).isSame(new Date(), 'day') ? '#FFF7E6' : 'transparent',
    },
  });

  const handleSelectEvent = (event) => {
    setSelectedSession(event);
  }
  

  return (
    <div>
      <Calendar
        localizer={localizer}
        defaultView='week'
        events={events}
        onSelectEvent={handleSelectEvent}
        style={{ height: 650 }}
        eventPropGetter={eventStyleGetter}
        dayPropGetter={dayPropGetter}
        components={{
          toolbar: CalendarToolbar,
          event: EventComponent,
          dateCellWrapper: (props) => <ColoredDateCellWrapper {...props} />,
        }}
        min={new Date(2020, 0, 23, 7, 0)}
        max={new Date(2050, 11, 31, 19, 0)}
      />
    </div>
  );
};

export default MyCalendar;
