import { useState, useEffect } from 'react';
import { useResponsiveHeight, useFetchData } from '../../hooks';
import { MyCalendar } from '../../components/calendar';
import { Alert } from '../../components/other';
import 'react-loading-skeleton/dist/skeleton.css';


const InstructorSchedule = () => {
  const minHeight = useResponsiveHeight();
  const email = localStorage.getItem('email');

  const [selectedSession, setSelectedSession] = useState(null);

  const { data: sessionData, error: fetchSessionsError, refresh: refreshEnrollments } = useFetchData(
    `instructor-sessions/${email}`, false
  );

  return (
    <div className="container p-4" style={{ minHeight }}>
      {sessionData ? (
        <>
          <div className="row px-2">
            <div className="col order-lg-1 order-2">
              <div className="container p-4 bg-light border border-4 border-primary rounded-4">
                <MyCalendar sessions={sessionData?.sessions} setSelectedSession={setSelectedSession} />
              </div>
            </div>
            
            <div className="col-lg-4 order-lg-2 order-1 ms-lg-4 mb-lg-0 mb-4">
              <div className="container p-4 border border-4 border-primary rounded-4">
                <h6 className='mb-2'>Remaining Sessions Today: {sessionData?.upcoming_sessions}</h6>
                <h6>Total Upcoming Sessions: {sessionData?.upcoming_sessions}</h6>
                <hr className="my-4" />
                {selectedSession ? (
                  <div className="row">
                    <p><strong>Date: </strong>{new Date(selectedSession.date).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })}</p>
                    <p><strong>Time: </strong>{selectedSession.time}</p>
                    <p><strong>Course Enrolled: </strong>{selectedSession.enrollment}</p>
                    <p><strong>Transmission Type: </strong>{selectedSession.transmission_type}</p>
                    <p><strong>Session Nth: </strong>{selectedSession.nth}</p>
                    
                    <p><strong>Branch: </strong>{selectedSession.branch}</p>
                    <p className="mb-0"><strong>Status: </strong>{selectedSession.status}</p>
                  </div>
                ) : (
                  <small>Select a schedule from the calendar to view its details.</small>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <Alert message="You have no recorded sessions as of now. Please inform the admin if you think there is a problem." />
      )}
    </div>
  );
};

export default InstructorSchedule;
