import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";


const CalendarToolbar = (toolbar) => {
  const userRole = localStorage.getItem('userRole');
  
  const goToBack = () => {
    toolbar.onNavigate('PREV');
  };

  const goToNext = () => {
    toolbar.onNavigate('NEXT');
  };

  const goToCurrent = () => {
    toolbar.onNavigate('TODAY');
  };

  const goToView = (view) => {
    toolbar.onView(view);
  };

  const getButtonClass = (view) => {
    return toolbar.view === view ? "btn btn-sm btn-dark" : "btn btn-sm btn-outline-dark";
  };
  

  return (
    <div className="custom-toolbar">
      <h5 className="text-start">{toolbar.label}</h5><br />
      <div className="d-flex justify-content-between align-items-center">
        <div className="btn-group">
          <button onClick={goToBack} className="btn btn-sm btn-outline-dark"><MdArrowBackIos/></button>
          <button onClick={goToCurrent} className="btn btn-sm btn-outline-dark">Today</button>
          <button onClick={goToNext} className="btn btn-sm btn-outline-dark"><MdArrowForwardIos/></button>
        </div>
        <div className="view-buttons btn-group">
          <button onClick={() => goToView('month')} className={getButtonClass('month')}>Month</button>
          {userRole === "Admin" || userRole === "Staff" 
            ? <button onClick={() => goToView('agenda')} className={getButtonClass('agenda')}>Agenda</button> 
            : <button onClick={() => goToView('week')} className={getButtonClass('week')}>Week</button>
          }
          <button onClick={() => goToView('day')} className={getButtonClass('day')}>Day</button>
        </div>
      </div>
      <hr />
    </div>
  );
};

export default CalendarToolbar;
