import { Students } from "../shared";


const branch = localStorage.getItem('branch');
const canEdit = localStorage.getItem('can_edit');

const StaffStudents = () => {
  return <Students role='Admin' branch={branch} canEdit={canEdit} />
}

export default StaffStudents;
