import axios from 'axios';
import api from '../api';
import { useState, useEffect, useRef } from 'react';

const useFetchData = (url, isPaginated = false, deps = []) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [totalItems, setTotalItems] = useState(0);

  const cancelToken = useRef(null);

  const fetchData = async () => {
    setLoading(true);
    setError(null);

    if (!url) {
      setLoading(false);
      setError('URL is not provided');
      return;
    }

    try {
      if (!cancelToken.current) {
        cancelToken.current = axios.CancelToken.source();
      }

      const response = await api.get(url, {
        cancelToken: cancelToken.current.token,
      });

      if (isPaginated && response.data.results) {
        setData(response.data.results);
        setTotalItems(response.data.count);
      } else {
        setData(response.data);
      }
    } catch (err) {
      // Handle request cancellations
      if (axios.isCancel(err)) {
        // Log request cancellation only in development
        if (process.env.NODE_ENV === 'development') {
          console.log('Request canceled:', err.message);
        }
      } else if (err.response) {
        // API error handling
        setError(`Error ${err.response.status}: ${err.response?.data?.error || 'An error occurred'}`);
        // Log API errors only in development
        if (process.env.NODE_ENV === 'development') {
          console.error('API Error:', err.response);
        }
      } else {
        // Handle network or unknown errors
        setError('Network error or invalid URL');
        // Log network/unknown errors only in development
        if (process.env.NODE_ENV === 'development') {
          console.error('Network or unknown error:', err);
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const refresh = async () => {
    fetchData();
  };

  useEffect(() => {
    if (!cancelToken.current) {
      cancelToken.current = axios.CancelToken.source();
    }

    fetchData();

    return () => {
      // Cancel ongoing request when component unmounts or dependencies change
      if (cancelToken.current) {
        cancelToken.current.cancel('Component unmounted or dependencies changed');
        cancelToken.current = null;
      }
    };
  }, [url, ...deps]);

  return { data, loading, error, totalItems, refresh };
};

export default useFetchData;
