import { useState } from 'react';
import { MdOutlineArrowBack } from "react-icons/md";
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer } from 'recharts';
import { Alert } from '../other';


const BarToBar = ({ primaryData, secondaryData }) => {
  const [selectedGroup, setSelectedGroup] = useState(null);

  const handleBarClick = (entry) => {
    setSelectedGroup(entry.name);
  };

  const handleBackClick = () => {
    setSelectedGroup(null);
  };
  

  if (!primaryData || primaryData.length === 0) {
    return <Alert message={"Nothing to show"} />;
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      {selectedGroup && (
        <div className='text-center'>
          <button onClick={handleBackClick} className='btn btn-outline-primary'>
            <MdOutlineArrowBack />
          </button>
        </div>
      )}
      <ResponsiveContainer width="100%" height={200}>
        {selectedGroup ? (
          <BarChart data={secondaryData[selectedGroup] || []} >
            <XAxis dataKey="name" tick={{ fontSize: 14 }} />
            <YAxis tick={{ fontSize: 14 }} />
            <Bar dataKey="count" fill="#F0A500" stroke="#211F1F" label={{ fill: '#F5F5F5', fontSize: 12 }} />
          </BarChart>
        ) : (
          <BarChart data={primaryData}>
            <XAxis dataKey="name" tick={{ fontSize: 14 }} />
            <YAxis tick={{ fontSize: 14 }} />
            <Bar
              dataKey="count" 
              fill="#F0A500" 
              stroke="#211F1F" 
              label={{ fill: '#F5F5F5', fontSize: 12 }} 
              onClick={handleBarClick}
            />
          </BarChart>
        )}
      </ResponsiveContainer>
    </div>
  );
};

export default BarToBar;
