import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const LoadingSkeleton = () => {
  return (
    <div className="skeleton-container">
      <div className="d-flex justify-content-between mb-4">
        <div className="flex-fill me-4">
          <Skeleton height={100} />
        </div>
        <div className="flex-fill me-4">
          <Skeleton height={100} />
        </div>
        <div className="flex-fill me-4">
          <Skeleton height={100} />
        </div>
        <div className="flex-fill">
          <Skeleton height={100} />
        </div>
      </div>

      <div className="d-flex justify-content-between mb-4">
        <div className="flex-fill me-4">
          <Skeleton height={200} />
        </div>
        <div className="flex-fill">
          <Skeleton height={200} />
        </div>
      </div>

      <div className="d-flex justify-content-between mb-4">
        <div className="flex-fill me-4">
          <Skeleton height={200} />
        </div>
        <div className="flex-fill">
          <Skeleton height={200} />
        </div>
      </div>

      <Skeleton height={200} className="mb-4" />

      <Skeleton height={50} count={5} className="mb-4" />
    </div>
  );
};

export default LoadingSkeleton;
