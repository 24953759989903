import { TextInput, PasswordInput, SelectInput, TextAreaInput, DatePickerInput, DatalistInput } from '../input';
import { ListProgress } from '../other';


const StudentForm = ({ studentData, onChange, onReset, onSave, branches, courses, formState }) => {
  return (
    <form>
      <div className="input-group">
        <TextInput 
          type="text"
          label="First Name" 
          name="first_name" 
          value={studentData?.first_name}
          onChange={onChange} 
          disabled={formState === 'view'}
        />
        <TextInput 
          type="text"
          label="Last Name" 
          name="last_name"
          value={studentData?.last_name}
          onChange={onChange} 
          disabled={formState === 'view'}
        />
      </div>
      <TextInput 
        type="text"
        label="Address" 
        name="address" 
        required={false}
        value={studentData?.address}
        onChange={onChange} 
        disabled={formState === 'view'}
      />
      <div className="input-group">
        <TextInput 
          type="text"
          label="Contact No." 
          name="contact_number" 
          required={false}
          value={studentData?.contact_number}
          onChange={onChange} 
          disabled={formState === 'view'}
        />
        <TextInput 
          type="text"
          label="Emergency Contact No." 
          name="emergency_number" 
          required={false}
          value={studentData?.emergency_number}
          onChange={onChange}
          disabled={formState === 'view'}
        />
      </div>
      { formState === 'view' &&
        <TextInput
          type="email"
          label="Email" 
          name="email" 
          required={false}
          value={studentData?.email || ""} 
          onChange={onChange} 
          disabled={formState === 'view'}
        />
      }

      <hr className='my-3' />
      <h6 className="mb-3">Enrollment Information</h6>

      {formState === "new" && (
        <>
          <SelectInput
            label="Branch"
            name="branch"
            value={studentData?.branch}
            onChange={onChange}
            options={branches ? branches.results.map(branch => ({ value: branch.branch_name, label: branch.branch_name })) : []} 
            disabled={formState === 'view'}
          />
          <SelectInput
            label="Course"
            name="course"
            value={studentData?.course}
            onChange={onChange}
            options={courses ? courses.results.map(course => ({ value: course.course_code, label: course.course_name })) : []}
            disabled={formState === 'view'}
          />
          <SelectInput
            label="Transmission Type"
            name="transmission_type"
            value={studentData?.transmission_type}
            onChange={onChange}
            options={[
              { value: 'MT', label: 'Manual Transmission' },
              { value: 'AT', label: 'Automatic Transmission' },
              { value: 'NA', label: 'Not Applicable' }
            ]}
            disabled={formState === 'view'}
          />
          <TextInput
            type="number"
            label="Total Hours"
            name="total_hours"
            value={studentData?.total_hours}
            onChange={onChange}
            disabled={formState === 'view'}
          />
        </>
      )}

      {formState === "new" && (
        <>
          <hr className='my-3' />
          <h6 className='mb-2'>Availability <span className='fw-normal'>(optional)</span></h6>
          <DatePickerInput
            label="Preferred Dates" 
            name="preferred_dates" 
            value={studentData?.preferred_dates || []}
            onChange={onChange} 
            multiple={true}
            disabled={formState === 'view'}
          />
          <TextAreaInput 
            label="Additional Information" 
            name="remarks" 
            value={studentData?.remarks} 
            onChange={onChange}
            disabled={formState === 'view'}
          />
        </>
      )}

      {formState !== "new" && studentData?.courses_enrolled?.length > 0 && (
        <ListProgress courses={studentData?.courses_enrolled} />
      )}
      
      <div className="modal-footer pe-0">
        {formState === 'new' && (
          <button onClick={onReset} type="button" className="btn btn-outline-secondary">
            Clear
          </button>
        )}
        {formState !== 'view' && (
          <button onClick={onSave} type="button" className="btn btn-primary" data-bs-dismiss="modal">
            Save {formState === "edit" && "Changes"}
          </button>
        )}
      </div>
    </form>
  );
};

export default StudentForm;
