import { useState } from 'react';
import { useDocumentTitle, useFetchData, useCrudHandlers, useLoading } from '../../hooks';
import { CourseForm } from '../../components/form';
import { FormModal } from '../../components/modal';
import { TableHeader, DataTable, Pagination } from '../../components/table';
import { Alert } from '../../components/other';


const Courses = () => {
  useDocumentTitle('Courses');
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [filterStatus, setFilterStatus] = useState('');
  const [sortField, setSortField] = useState('course_code');
  const [sortOrder, setSortOrder] = useState('asc');
  const itemsPerPage = 15;
  const loading = useLoading(1000);

  // FETCH DATA with additional query params
  const { data: courses, error: fetchCoursesError, totalItems, refresh } = useFetchData(
    `courses/?page=${currentPage}&search=${searchQuery}&course_category=${filterStatus}&ordering=${sortOrder === 'asc' ? '' : '-'}${sortField}`,
    true,
    [searchQuery, filterStatus, sortField, sortOrder, currentPage]
  );

  // FETCH DATA for categories
  const { data: categories, error: fetchCategoriesError} = useFetchData('course-categories/');

  const initialFormData = { course_code: '', course_name: '', course_category: '', course_description: '' };

  // CRUD handlers
  const {
    formData, setFormData, selectedId, setSelectedId, formState, setFormState,
    handleSave, handleArchive, handleModalClose, fetchError,
  } = useCrudHandlers('courses/', 'courses/', initialFormData, [refresh], refresh);

  // TABLE headers and rows
  const headers = ['Course Code', 'Course Name', 'Course Category', 'Status'];
  const rows = courses ? courses.map(course => [
    course.course_code,  course.course_name, course.course_category, course.status,
  ]) : [];

  return (
    <div className="custom-container">
      <TableHeader
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        filterOptions={ categories ? [{ value: "", label: "..." }, ...categories.map(category => ({
          value: category.category_code, label: category.category_code, }))] : [] }
        setFilterStatus={setFilterStatus}
        setSortField={setSortField}
        setSortOrder={setSortOrder}
      />
      {fetchCoursesError && <Alert message={fetchCoursesError} />}
      {fetchError && <Alert message={fetchError} />}
      <DataTable
        headers={headers}
        rows={rows}
        loading={loading}
        primaryKeyIndex={0}
        setSelectedId={setSelectedId}
        setFormState={setFormState}
        handleArchive={handleArchive}
        setSortField={setSortField}
        setSortOrder={setSortOrder}
      />
      <Pagination
        setCurrentPage={setCurrentPage}
        totalItems={totalItems}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
      />
      <FormModal
        title={formState === 'new' ? 'New Course' : formState === 'edit' ? `Edit ${selectedId}` : `${selectedId}`}
        open={selectedId !== null}
        onClose={handleModalClose}
      >
        <CourseForm
          courseData={formData}
          categories={categories}
          formState={formState}
          onChange={(e) => setFormData(prevData => ({ ...prevData, [e.target.name]: e.target.value }))}
          onReset={handleModalClose}
          onSave={handleSave}
        />
      </FormModal>
    </div>
  );
};

export default Courses;
