import React, { useState } from 'react';
import { PasswordInput } from '../input';
import api from '../../api';

const ArchiveModal = ({ onConfirm, archiveId }) => {
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  
  const handleClose = () => {
    setPassword('');
    setErrorMessage('');
  };

  const handleConfirm = async () => {
    const userData = new FormData();
    userData.append('email', localStorage.getItem('email'));
    userData.append('password', password);
    
    try {
      const response = await api.post('confirm-password/', userData);
      if (response.data.success) {
        onConfirm();
        handleClose();
      } else {
        setErrorMessage('Incorrect password. Please try again.');
      }
    } catch (error) {
      console.error('Password confirmation failed:', error);
      setErrorMessage('An error occurred. Please try again later.');
    }
  };

  return (
    <div className="modal fade" id="confirm-modal" tabIndex="-1" aria-hidden="true" data-bs-backdrop="static">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="confirm-modal-label">Confirm Delete</h5>
          </div>
          <div className="modal-body text-justify">
            <p>Are you sure you want to archive <strong>{archiveId}</strong>?</p>
            <ul>
              <li><small>You cannot undo this action.</small></li>
              <li><small>Once archived, this record will no longer be available in the system.</small></li>
              <li><small>You would have to contact the database administrator for any recovery or modifications.</small></li>
            </ul>
            <hr />
            <p>Please enter your password to confirm:</p>
            <PasswordInput
              label="Password"
              name="password"
              value={password}
              validateStrength={false}
              onChange={(e) => setPassword(e.target.value)}
            />
            {errorMessage && <div className="alert alert-danger mt-2">{errorMessage}</div>}
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={handleClose}>Cancel</button>
            <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={handleConfirm}>Confirm</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArchiveModal;
