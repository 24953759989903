import { useState } from 'react';
import { useDocumentTitle, useFetchData, useCrudHandlers, useLoading } from '../../hooks';
import { InstructorForm } from '../../components/form';
import { FormModal } from '../../components/modal';
import { TableHeader, DataTable, Pagination } from '../../components/table';
import { Alert } from '../../components/other';


const Instructors = () => {
  useDocumentTitle('Instructors');
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [filters, setFilters] = useState({ status: '', date: '', startTime: '', endTime: '' });
  const [sortField, setSortField] = useState('instructor_code');
  const [sortOrder, setSortOrder] = useState('asc');
  const itemsPerPage = 15;
  const loading = useLoading(1000);

  // FETCH DATA with additional query params
  const { data: instructors, error: fetchInstructorsError, totalItems, refresh } = useFetchData(
    `instructors/?page=${currentPage}&search=${searchQuery}&branch=${filters.status}&date=${filters.date}&start_time=${filters.startTime}&end_time=${filters.endTime}&ordering=${sortOrder === 'asc' ? '' : '-'}${sortField}`,
    true,
    [searchQuery, filters, sortField, sortOrder, currentPage]
  );

  // FETCH DATA for options
  const { data: branches, error: fetchBranchesError} = useFetchData('valid-branches/');
  const { data: users, error: fetchUsers} = useFetchData('users/?condition=no_association&role=Instructor');

  const initialFormData = { first_name : '', last_name: '', is_senior : '', branch_name : '', status : '', email : '', password : '', re_password: '',};

  // CRUD handlers
  const {
    formData, setFormData, selectedId, setSelectedId, formState, setFormState,
    handleSave, handleArchive, handleModalClose, fetchError,
  } = useCrudHandlers('create-instructor/', 'instructors/', initialFormData, [refresh], refresh);

  // TABLE headers and rows
  const headers = ['Instructor Code', 'First Name', 'Is Senior', 'Branch', 'Status'];
  const rows = instructors ? instructors.map(instructor => [
    instructor.instructor_code, instructor.first_name, instructor.is_senior ? 'Yes' : 'No', instructor.branch, instructor.status
  ]) : [];

  return (
    <div className="custom-container">
      <TableHeader
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        filterOptions={ branches ? [{ value: "", label: "..." }, ...branches.results.map(branch => ({
          value: branch.branch_name, label: branch.branch_name, }))] : [] }
        setFilters={setFilters}
        setSortField={setSortField}
        setSortOrder={setSortOrder}
      />
      {fetchInstructorsError && <Alert message={fetchInstructorsError} />}
      {fetchError && <Alert message={fetchError} />}
      <DataTable
        headers={headers}
        rows={rows}
        loading={loading}
        primaryKeyIndex={0}
        setSelectedId={setSelectedId}
        setFormState={setFormState}
        handleArchive={handleArchive}
        setSortField={setSortField}
        setSortOrder={setSortOrder}
      />
      <Pagination
        setCurrentPage={setCurrentPage}
        totalItems={totalItems}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
      />
      <FormModal
        title={formState === 'new' ? 'New Instructor' : formState === 'edit' ? `Edit ${selectedId}` : `${selectedId}`}
        open={selectedId !== null}
        onClose={handleModalClose}
      >
        <InstructorForm
          instructorData={formData}
          branches={branches}
          users={users}
          formState={formState}
          onChange={(e) => setFormData(prevData => ({ ...prevData, [e.target.name]: e.target.value }))}
          onReset={handleModalClose}
          onSave={handleSave}
        />
      </FormModal>
    </div>
  );
};

export default Instructors;
