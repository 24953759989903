import { PieStatic } from '../chart';

const ListProgress = ({ courses }) => {
  return (
    <ul className="list-group border border-secondary rounded-3 mb-3">
      {courses?.map((course, index) => {
        const courseProgress = Math.round((course?.completed_sessions || 0) / (course?.total_sessions || 1) * 100);

        return (
          <li key={index} className="list-group-item">
            <PieStatic
              data={[
                { name: 'Utilization', value: courseProgress },
                { name: '', value: 100 - courseProgress },
              ]}
              label="Progress"
            />
            <div className="d-flex flex-column flex-sm-row justify-content-between lh-sm">
              <div className="text-start mb-sm-0">
                <h6 className="my-0">{course.course_name} - {course.transmission_type}</h6>
              </div>
              <div className="text-start text-lg-end">
                <span className="text-dark">{course.total_hours} hours</span><br />
                <small className="text-body-secondary">{course.enrollment_status}</small>
              </div>
            </div>
          </li>
        );
      })}
    </ul>
  );
};

export default ListProgress;
