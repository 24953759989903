import { useState } from 'react';
import { useDocumentTitle, useFetchData, useCrudHandlers, useLoading } from '../../hooks';
import { UserForm } from '../../components/form';
import { FormModal } from '../../components/modal';
import { TableHeader, DataTable, Pagination } from '../../components/table';
import { Alert } from '../../components/other';


const Users = () => {
  useDocumentTitle('Users');
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [filterStatus, setFilterStatus] = useState('');
  const [sortField, setSortField] = useState('role');
  const [sortOrder, setSortOrder] = useState('asc');
  const itemsPerPage = 15;
  const loading = useLoading(1000);

  // FETCH DATA with additional query params
  const { data: users, error: fetchUsersError, totalItems, refresh } = useFetchData(
    `users/?page=${currentPage}&search=${searchQuery}&role=${filterStatus}&ordering=${sortOrder === 'asc' ? '' : '-'}${sortField}`,
    true,
    [searchQuery, filterStatus, sortField, sortOrder, currentPage]
  );

  // FETCH DATA for options
  const { data: branches, error: fetchBranchesError} = useFetchData('valid-branches/');

  const initialFormData = { role: '', email: '', first_name: '', last_name: '', is_active: true, can_edit: false, password: '', re_password: '' };

  // CRUD handlers
  const {
    formData, setFormData, selectedId, setSelectedId, formState, setFormState,
    handleSave, handleArchive, handleModalClose, fetchError,
  } = useCrudHandlers('users/', 'users/', initialFormData, [refresh], refresh);

  // TABLE headers and rows
  const headers = ['Role', 'Email', 'First Name', 'Is Active', 'Can Edit'];
  const rows = users ? users.map(user => [
    user.role,  user.email, user.first_name, user.is_active ? 'Yes' : 'No', user.can_edit ? 'Yes' : 'No'
  ]) : [];

  return (
    <div className="custom-container">
      <TableHeader
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        const filterOptions = {[ { value: '', label: '...' }, { value: 'Admin', label: 'Admin' }, { value: 'Staff', label: 'Staff' },
          { value: 'Instructor', label: 'Instructor' }, { value: 'Student', label: 'Student' }
        ]}
        setFilterStatus={setFilterStatus}
        setSortField={setSortField}
        setSortOrder={setSortOrder}
      />
      {fetchUsersError && <Alert message={fetchUsersError} />}
      {fetchError && <Alert message={fetchError} />}
      <DataTable
        headers={headers}
        rows={rows}
        loading={loading}
        primaryKeyIndex={1}
        setSelectedId={setSelectedId}
        setFormState={setFormState}
        handleArchive={handleArchive}
        setSortField={setSortField}
        setSortOrder={setSortOrder}
      />
      <Pagination
        setCurrentPage={setCurrentPage}
        totalItems={totalItems}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
      />
      <FormModal
        title={formState === 'new' ? 'New User' : formState === 'edit' ? `Edit ${selectedId}` : `${selectedId}`}
        open={selectedId !== null}
        onClose={handleModalClose}
      >
        <UserForm
          userData={formData}
          branches={branches}
          formState={formState}
          onChange={(e) => setFormData(prevData => ({ ...prevData, [e.target.name]: e.target.value }))}
          onReset={handleModalClose}
          onSave={handleSave}
        />
      </FormModal>
    </div>
  );
};

export default Users;
