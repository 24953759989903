import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const TableSkeleton = ({ numRows = 10, numColumns = 3 }) => {
  return (
    <table className="table table-striped table-light my-4">
      <thead>
        <tr>
          {[...Array(numColumns)].map((_, index) => (
            <th key={index}>
              <Skeleton width={100} height={20} />
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {[...Array(numRows)].map((_, rowIndex) => (
          <tr key={rowIndex}>
            {[...Array(numColumns)].map((_, cellIndex) => (
              <td key={cellIndex}>
                <Skeleton width={100} height={20} />
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TableSkeleton;