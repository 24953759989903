import { BsExclamationTriangle } from "react-icons/bs";

const Alert = (message) => (
  <div className="alert alert-primary d-flex align-items-center my-2" role="alert">
    <BsExclamationTriangle className="me-2 fs-6 text-center text-secondary"/>
    <small className="text-wrap">
      {message.message}
    </small>
  </div>
);

export default Alert;