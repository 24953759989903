import axios from 'axios';


const baseURL =
  process.env.NODE_ENV === 'production'
    ? 'https://top-drive-backend.onrender.com/api/'
    : 'http://127.0.0.1:8000/api/';

const api = axios.create({
  baseURL: baseURL,
});

console.log(api);

export default api;