import { useState } from 'react';
import { useDocumentTitle, useFetchData, useCrudHandlers, useLoading } from '../../hooks';
import { VehicleForm } from '../../components/form';
import { FormModal } from '../../components/modal';
import { TableHeader, DataTable, Pagination } from '../../components/table';
import { Alert } from '../../components/other';


const Vehicles = () => {
  useDocumentTitle('Vehicles');
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [filters, setFilters] = useState({ status: '', date: '', startTime: '', endTime: '' });
  const [sortField, setSortField] = useState('vehicle_code');
  const [sortOrder, setSortOrder] = useState('asc');
  const itemsPerPage = 15;
  const loading = useLoading(1000);

  // FETCH DATA with additional query params
  const { data: vehicles, error: fetchVehiclesError, totalItems, refresh } = useFetchData(
    `vehicles/?page=${currentPage}&search=${searchQuery}&branch=${filters.status}&date=${filters.date}&start_time=${filters.startTime}&end_time=${filters.endTime}&ordering=${sortOrder === 'asc' ? '' : '-'}${sortField}`,
    true, [searchQuery, filters, sortField, sortOrder, currentPage]
  );

  // FETCH DATA for options
  const { data: branches, error: fetchBranchesError} = useFetchData('valid-branches/');

  const initialFormData = { vehicle_code: '', transmission_type: '', wheel_num: '', vehicle_model: '', color: '', manufacturer: '', branch: '', status: ''};

  // CRUD handlers
  const {
    formData, setFormData, selectedId, setSelectedId, formState, setFormState,
    handleSave, handleArchive, handleModalClose, fetchError,
  } = useCrudHandlers('vehicles/', 'vehicles/', initialFormData, [refresh], refresh);

  // TABLE headers and rows
  const headers = ['Vehicle Code', 'Vehicle Model', 'Color', 'Branch', 'Status'];
  const rows = vehicles ? vehicles.map(vehicle => [
    vehicle.vehicle_code,  vehicle.vehicle_model, vehicle.color, vehicle.branch, vehicle.status
  ]) : [];

  return (
    <div className="custom-container">
      <TableHeader
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        filterOptions={ branches ? [{ value: "", label: "..." }, ...branches.results.map(branch => ({
          value: branch.branch_name, label: branch.branch_name, }))] : [] }
        setFilters={setFilters}
        setSortField={setSortField}
        setSortOrder={setSortOrder}
      />
      {fetchVehiclesError && <Alert message={fetchVehiclesError} />}
      {fetchError && <Alert message={fetchError} />}
      <DataTable
        headers={headers}
        rows={rows}
        loading={loading}
        primaryKeyIndex={0}
        setSelectedId={setSelectedId}
        setFormState={setFormState}
        handleArchive={handleArchive}
        setSortField={setSortField}
        setSortOrder={setSortOrder}
      />
      <Pagination
        setCurrentPage={setCurrentPage}
        totalItems={totalItems}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
      />
      <FormModal
        title={formState === 'new' ? 'New Vehicle' : formState === 'edit' ? `Edit ${selectedId}` : `${selectedId}`}
        open={selectedId !== null}
        onClose={handleModalClose}
      >
        <VehicleForm
          vehicleData={formData}
          branches={branches}
          formState={formState}
          onChange={(e) => setFormData(prevData => ({ ...prevData, [e.target.name]: e.target.value }))}
          onReset={handleModalClose}
          onSave={handleSave}
        />
      </FormModal>
    </div>
  );
};

export default Vehicles;
