import { useState } from 'react';
import { PieChart, Pie, Cell, BarChart, Bar, XAxis, YAxis, ResponsiveContainer, Legend, Sector } from 'recharts';
import { MdOutlineArrowBack } from "react-icons/md";
import { Alert } from "../other";


const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, percentage } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 15) * cos;
  const my = cy + (outerRadius + 15) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';


  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{percentage}</text>
    </g>
  );
};


const PieToBar = ({ pieData, barData, innerRadius = 50, outerRadius = 60, height = 200, isDarkMode = false }) => {
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);

  const lightPieColors = ['#F0A500', '#F7C251', '#FCD98C', '#D68F00'];
  const darkPieColors = ['#1E1C1C', '#3E3C3C', '#484646'];
  const pieColors = isDarkMode ? darkPieColors : lightPieColors;

  const handlePieClick = (entry, index) => {
    setSelectedGroup(entry.name);
    setActiveIndex(index);
  };

  const handleBackClick = () => {
    setSelectedGroup(null);
    setActiveIndex(0);
  };

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };
  

  if (!pieData || pieData.length === 0) {
    return <Alert message={"Nothing to show"} />;
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between'}}>
      {selectedGroup && (
        <div className="text-center">
          <button onClick={handleBackClick} className={`btn ${isDarkMode ? 'btn-outline-light' : 'btn-outline-primary'}`}>
            <MdOutlineArrowBack />
          </button>
        </div>
      )}
      <ResponsiveContainer width="100%" height={height} gap="5px">
        {selectedGroup ? (
          <BarChart 
            data={barData[selectedGroup]} 
            margin={0}
          >
            <XAxis 
              dataKey="name" 
              stroke={isDarkMode ? "#F5F5F5" : "#000"} 
              tick={{ fill: isDarkMode ? '#F5F5F5' : '#000', fontSize: 12 }} 
            />
            <YAxis 
              stroke={isDarkMode ? "#F5F5F5" : "#000"} 
              tick={{ fill: isDarkMode ? '#F5F5F5' : '#000', fontSize: 12 }} 
            />
            <Bar 
              dataKey="percentage" 
              fill={isDarkMode ? '#343232' : '#F0A500'} 
              label={{ fill: isDarkMode ? '#F5F5F5' : '#000', fontSize: 12 }} 
            />
          </BarChart>
        ) : (
          <PieChart>
            <Pie
              activeIndex={activeIndex}
              activeShape={renderActiveShape}
              data={pieData}
              cx="50%"
              cy="50%"
              innerRadius={innerRadius}
              outerRadius={outerRadius}
              dataKey="percentage"
              onClick={handlePieClick}
              onMouseEnter={onPieEnter}
            >
              {pieData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={pieColors[index % pieColors.length]} />
              ))}
            </Pie>
            <Legend 
              layout="horizontal" 
              align="center" 
              verticalAlign="top" 
              wrapperStyle={{ fontSize: 14 }}
            />
          </PieChart>
        )}
      </ResponsiveContainer>
    </div>
  );
};

export default PieToBar;
