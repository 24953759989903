import { useDocumentTitle, useFetchData, useResponsiveHeight } from '../../hooks';
import { InfoCard } from '../../components/other';


const CourseDetails = () => {
  useDocumentTitle("Course Details");
  const minHeight = useResponsiveHeight();
  const { data: courses, error: fetchCoursesError } = useFetchData('valid-courses/');

  // GROUP COURSES BY CATEGORY
  const groupedCourses = courses?.results.reduce((acc, course) => {
    const category = course.category_name || 'General';
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(course);
    return acc;
  }, {});


  return (
      <div className="container" style={{ minHeight: minHeight }}>
        {groupedCourses && Object.entries(groupedCourses).map(([category, courseCategories], idx) => (
          <div key={idx} className="mb-5">
            <div className="p-3 pb-md-5 mx-auto text-center">
              <h1 className="fw-bold text-light">{category}</h1>
              <p className="fs-6 text-light mt-2">
                Explore our range of {category} courses to find the one that fits your needs.
              </p>
            </div>

            <div className="row row-cols-1 row-cols-md-3 mb-3 text-center">
              {courseCategories.map((course, index) => (
                <InfoCard
                  key={index}
                  header={course.course_code}
                  title={course.course_name}
                  description={course.course_description}
                />
              ))}
            </div>
            <hr className='text-light'/>
          </div>
        ))}
      </div>
    
  );
}

export default CourseDetails;
