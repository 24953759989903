import { useState } from 'react';
import { useDocumentTitle, useFetchData, useCrudHandlers, useLoading } from '../../hooks';
import { ClassroomForm } from '../../components/form';
import { FormModal } from '../../components/modal';
import { TableHeader, DataTable, Pagination } from '../../components/table';
import { Alert } from '../../components/other';


const Classrooms = () => {
  useDocumentTitle('Classrooms');
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [filters, setFilters] = useState({ status: '', date: '', startTime: '', endTime: '' });
  const [sortField, setSortField] = useState('classroom_code');
  const [sortOrder, setSortOrder] = useState('asc');
  const itemsPerPage = 15;
  const loading = useLoading(1000);

  // FETCH DATA with additional query params
  const { data: classrooms, error: fetchClassroomsError, totalItems, refresh } = useFetchData(
    `classrooms/?page=${currentPage}&search=${searchQuery}&branch=${filters.status}&date=${filters.date}&start_time=${filters.startTime}&end_time=${filters.endTime}&ordering=${sortOrder === 'asc' ? '' : '-'}${sortField}`,
    true,
    [searchQuery, filters, sortField, sortOrder, currentPage]
  );

  // FETCH DATA for options
  const { data: branches, error: fetchBranchesError} = useFetchData('branches/');

  const initialFormData = { classroom_code: '', capacity: '', branch: '', status: '' };

  // CRUD handlers
  const {
    formData, setFormData, selectedId, setSelectedId, formState, setFormState,
    handleSave, handleArchive, handleModalClose, fetchError,
  } = useCrudHandlers('classrooms/', 'classrooms/', initialFormData, [refresh], refresh);

  // TABLE headers and rows
  const headers = ['Classroom Code', 'Capacity', 'Branch', 'Status'];
  if (filters.date && filters.startTime && filters.endTime) {
    headers.push('Slots Available');
  }
  const rows = classrooms
    ? classrooms.map(classroom => {
        const row = [
          classroom.classroom_code,
          classroom.capacity,
          classroom.branch,
          classroom.status,
        ];
        if (filters.date && filters.startTime && filters.endTime) {
          row.push(classroom.slots_available || 'N/A');
        }
        return row;
      })
    : [];

  return (
    <div className="custom-container">
      <TableHeader
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        filterOptions={ branches ? [{ value: "", label: "..." }, ...branches.results.map(branch => ({
          value: branch.branch_name, label: branch.branch_name, }))] : [] }
        setFilters={setFilters}
        setSortField={setSortField}
        setSortOrder={setSortOrder}
      />
      {fetchClassroomsError && <Alert message={fetchClassroomsError} />}
      {fetchError && <Alert message={fetchError} />}
      <DataTable
        headers={headers}
        rows={rows}
        loading={loading}
        primaryKeyIndex={0}
        setSelectedId={setSelectedId}
        setFormState={setFormState}
        handleArchive={handleArchive}
        setSortField={setSortField}
        setSortOrder={setSortOrder}
      />
      <Pagination
        setCurrentPage={setCurrentPage}
        totalItems={totalItems}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
      />
      <FormModal
        title={formState === 'new' ? 'New Classroom' : formState === 'edit' ? `Edit ${selectedId}` : `${selectedId}`}
        open={selectedId !== null}
        onClose={handleModalClose}
      >
        <ClassroomForm
          classroomData={formData}
          branches={branches}
          formState={formState}
          onChange={(e) => setFormData(prevData => ({ ...prevData, [e.target.name]: e.target.value }))}
          onReset={handleModalClose}
          onSave={handleSave}
        />
      </FormModal>
    </div>
  );
};

export default Classrooms;