import { useState } from 'react';
import { useDocumentTitle, useFetchData, useCrudHandlers, useLoading } from '../../hooks';
import { BranchForm } from '../../components/form';
import { FormModal } from '../../components/modal';
import { TableHeader, DataTable, Pagination } from '../../components/table';
import { Alert } from '../../components/other';


const Branches = () => {
  useDocumentTitle('Branches');
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [filterStatus, setFilterStatus] = useState('');
  const [sortField, setSortField] = useState('branch_name');
  const [sortOrder, setSortOrder] = useState('asc');
  const [errors, setErrors] = useState({});
  const itemsPerPage = 15;
  const loading = useLoading(1000);

  // FETCH DATA with additional query params
  const { data: branches, error: fetchBranchesError, refresh, totalItems } = useFetchData(
    `branches/?page=${currentPage}&search=${searchQuery}&status=${filterStatus}&ordering=${sortOrder === 'asc' ? '' : '-'}${sortField}`,
    true,
    [searchQuery, filterStatus, sortField, sortOrder, currentPage]
  );

  const initialFormData = { branch_name: '', branch_address: '', status: '' };

  // CRUD handlers
  const {
    formData, setFormData, selectedId, setSelectedId, formState, setFormState,
    handleSave, handleArchive, handleModalClose, fetchError,
  } = useCrudHandlers('branches/', 'branches/', initialFormData, [refresh], refresh);

  // TABLE headers and rows
  const headers = ['Branch Name', 'Branch Address', 'Status'];
  const rows = branches ? branches.map(branch => [
    branch.branch_name,
    branch.branch_address,
    branch.status,
  ]) : [];
  

  return (
    <div className="custom-container">
      <TableHeader
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        filterOptions={[{ value: '', label: '...' }, { value: 'Open', label: 'Open' }, { value: 'Closed', label: 'Closed' }]}
        setFilterStatus={setFilterStatus}
        setSortField={setSortField}
        setSortOrder={setSortOrder}
      />
      {fetchBranchesError && <Alert message={fetchBranchesError} />}
      {fetchError && <Alert message={fetchError} />}
      <DataTable
        headers={headers}
        rows={rows}
        loading={loading}
        primaryKeyIndex={0}
        setSelectedId={setSelectedId}
        setFormState={setFormState}
        handleArchive={handleArchive}
        setSortField={setSortField}
        setSortOrder={setSortOrder}
      />
      <Pagination
        setCurrentPage={setCurrentPage}
        totalItems={totalItems}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
      />
      <FormModal
        title={formState === 'new' ? 'New Branch' : formState === 'edit' ? `Edit ${selectedId} Branch` : `${selectedId} Branch`}
        open={selectedId !== null}
        onClose={handleModalClose}
      >
        <BranchForm
          branchData={formData}
          formState={formState}
          onChange={(e) => setFormData(prevData => ({ ...prevData, [e.target.name]: e.target.value }))}
          onReset={handleModalClose}
          onSave={handleSave}
          errors={errors}
          setErrors={setErrors}
        />
      </FormModal>
    </div>
  );
};

export default Branches;