// import { useState, useEffect } from 'react';
// import { usePostData, usePutData, useFetchData } from '../hooks';

// export const useCrudHandlers = (postUrl, baseUrl, initialFormData, deps = [], refresh) => {
//   const [formData, setFormData] = useState(initialFormData);
//   const [selectedId, setSelectedId] = useState(null);
//   const [formState, setFormState] = useState('new');

//   const { data: entityDetails, error: fetchError } = useFetchData(
//     selectedId ? `${baseUrl}${selectedId}/` : null,
//   );

//   useEffect(() => {
//     if (entityDetails) {
//       setFormData(entityDetails);
//     }
//   }, [entityDetails]);

//   // API REQUESTS (POST AND PUT)
//   const { postData } = usePostData(postUrl, formData);
//   console.log(formData);
//   const { putData } = usePutData(`${baseUrl}${selectedId}/`, formData);
//   const archivedData = { ...formData, status: 'Archived' };
//   const { putData: archiveData } = usePutData(`${baseUrl}${selectedId}/`, archivedData);

//   // CREATE / UPDATE
//   const handleSave = async () => {
//     try {
//       if (formState === 'new') {
//         await postData(formData);
//       } else {
//         await putData(formData);
//       }
//       if (refresh) refresh();
//     } catch (err) {
//       console.error('Failed to save data:', err);
//     }
//     handleModalClose();
//   };

//   // ARCHIVE
//   const handleArchive = async () => {
//     try {
//       await archiveData(archivedData);
//       if (refresh) refresh();
//     } catch (err) {
//       console.error('Failed to archive data:', err);
//     }
//   };

//   const handleModalClose = () => {
//     setSelectedId(null);
//     setFormState('new');
//     setFormData(initialFormData);
//   };

//   return {
//     formData,
//     setFormData,
//     selectedId,
//     setSelectedId,
//     formState,
//     setFormState,
//     handleSave,
//     handleArchive,
//     handleModalClose,
//     fetchError,
//   };
// };

import { useState, useEffect, useCallback } from 'react';
import { usePostData, usePutData, useFetchData } from '../hooks';

export const useCrudHandlers = (postUrl, baseUrl, initialFormData, deps = [], refresh) => {
  const [formData, setFormData] = useState(initialFormData);
  const [selectedId, setSelectedId] = useState(null);
  const [formState, setFormState] = useState('new');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch entity details when selectedId is available
  const { data: entityDetails, error: fetchError } = useFetchData(
    selectedId ? `${baseUrl}${selectedId}/` : null,
  );

  useEffect(() => {
    if (entityDetails) {
      setFormData(entityDetails);
    }
  }, [entityDetails]);

  // API Requests
  const { postData } = usePostData(postUrl, formData);
  const { putData } = usePutData(`${baseUrl}${selectedId}/`, formData);
  const archivedData = { ...formData, status: 'Archived' };
  const { putData: archiveData } = usePutData(`${baseUrl}${selectedId}/`, archivedData);

  // Handle form save (Create / Update)
  const handleSave = useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      if (formState === 'new') {
        await postData(formData);
      } else {
        await putData(formData);
      }
      if (refresh) refresh();
      handleModalClose();
    } catch (err) {
      setError('Failed to save data. Please try again later.');
      console.error('Save Error:', err);
    } finally {
      setLoading(false);
    }
  }, [formState, formData, postData, putData, refresh]);

  // Handle archiving of data
  const handleArchive = useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      await archiveData(archivedData);
      if (refresh) refresh();
    } catch (err) {
      setError('Failed to archive data. Please try again later.');
      console.error('Archive Error:', err);
    } finally {
      setLoading(false);
    }
  }, [archivedData, archiveData, refresh]);

  // Reset form and modal state
  const handleModalClose = () => {
    setSelectedId(null);
    setFormState('new');
    setFormData(initialFormData);
  };

  // Expose state and handlers
  return {
    formData,
    setFormData,
    selectedId,
    setSelectedId,
    formState,
    setFormState,
    handleSave,
    handleArchive,
    handleModalClose,
    loading,
    error: error || fetchError,  // Combine internal and fetch errors
  };
};
