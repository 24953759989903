import { MdMenu, MdMenuOpen } from "react-icons/md";


const Header = ({ toggleSidebar, isSidebarOpen, pageTitle }) => {
  return (
    <header>
      <div className="custom-container mb-4 d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          <button 
            type="button" 
            className="btn-icon me-3"
            onClick={toggleSidebar}
          >
            {isSidebarOpen ? <MdMenuOpen /> : <MdMenu />}
          </button>
          <h2 className="mb-0">{pageTitle}</h2>
        </div>
      </div>
    </header>
  );
}

export default Header;