import { useState, useEffect } from 'react';
import { useResponsiveHeight, useFetchData } from '../../hooks';
import { MyCalendar } from '../../components/calendar';
import 'react-loading-skeleton/dist/skeleton.css';


const Schedule = () => {
  const minHeight = useResponsiveHeight();
  const email = localStorage.getItem('email');

  const [selectedSession, setSelectedSession] = useState(null);
  const [studentData, setStudentData] = useState(null);

  const { data: studentDataFromApi, error: fetchStudentDataError } = useFetchData(
    `student-enrollments/${email}`, false
  );

  const { data: enrollments, error: fetchSessionsError, refresh: refreshEnrollments } = useFetchData(
    studentData ? `student-sessions/${studentData.student_code}` : null, false
  );

  useEffect(() => {
    if (studentDataFromApi) setStudentData(studentDataFromApi);
  }, [studentDataFromApi]);

  useEffect(() => {
    if (studentData) refreshEnrollments();
  }, [studentData]);

  const sessions = enrollments?.reduce(
    (allSessions, enrollment) => [...allSessions, ...enrollment.sessions],
    []
  );

  const upcomingSessions = studentData?.courses_enrolled.reduce(
    (sum, course) => sum + (course.scheduled_sessions - course.completed_sessions),
    0
  );

  return (
    <div className="container p-4" style={{ minHeight }}>
      <div className="row px-2">
        <div className="col order-lg-1 order-2">
          <div className="container p-4 bg-light border border-4 border-primary rounded-4">
            <MyCalendar sessions={sessions} setSelectedSession={setSelectedSession} />
          </div>
        </div>
        
        <div className="col-lg-4 order-lg-2 order-1 ms-lg-4 mb-lg-0 mb-4">
          <div className="container p-4 border border-4 border-primary rounded-4">
            <h6>Total Upcoming Sessions: {upcomingSessions}</h6>
            <hr className="my-4" />
            {selectedSession ? (
              <div className="row">
                <p><strong>Course Enrolled: </strong>{selectedSession.enrollment}</p>
                <p><strong>Transmission Type: </strong>{selectedSession.transmission_type}</p>
                <p><strong>Session Nth: </strong>{selectedSession.nth}</p>
                <p><strong>Date: </strong>{selectedSession.date}</p>
                <p><strong>Time: </strong>{selectedSession.time}</p>
                <p><strong>Branch: </strong>{selectedSession.branch}</p>
                <p className="mb-0"><strong>Status: </strong>{selectedSession.status}</p>
              </div>
            ) : (
              <small>Select a schedule from the calendar to view its details.</small>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Schedule;
