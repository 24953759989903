import { useState } from 'react';
import { BsThreeDotsVertical } from "react-icons/bs";
import { RiExpandUpDownFill } from "react-icons/ri";
import { MdOutlineViewHeadline, MdOutlineEdit, MdDeleteOutline, MdArrowDropUp, MdArrowDropDown } from "react-icons/md";
import { TableSkeleton } from './';
import { ArchiveModal } from '../modal';


const DataTable = ({ headers, rows, loading, primaryKeyIndex, 
  setSelectedId, setFormState, handleArchive, setSortField, setSortOrder, adminOnly=true }) => {
  
  const role = localStorage.getItem('userRole');
  const permitted = localStorage.getItem('canEdit');
  const [sortDirection, setSortDirection] = useState({});
  const [archiveId, setArchiveId] = useState(null);

  const handleSort = (header) => {
    const sortKey = header.toLowerCase().replace(/ /g, '_');
    const currentDirection = sortDirection[sortKey] === 'asc' ? 'desc' : 'asc';
    setSortDirection({ [sortKey]: currentDirection });
    setSortField(sortKey);
    setSortOrder(currentDirection);
  };

  const handleEdit = (id) => {
    setSelectedId(id);
    setFormState('edit');
  };

  const handleView = (id) => {
    setSelectedId(id);
    setFormState('view');
  };

  const handleArchiveClick = (id) => {
    setSelectedId(id);
  };
  

  return (
    <div className="table-responsive">
      {loading ? (
        <TableSkeleton numRows={11} numColumns={headers.length + 1} />
      ) : (
        <table className="table table-striped my-4 border border-dark bg-gradient-primary" style={{ fontSize: '0.9rem' }}>
          <thead>
            <tr>
              {headers.map((header, index) => {
                const sortKey = header.toLowerCase().replace(/ /g, '_');
                const currentDirection = sortDirection[sortKey];

                return (
                  <th
                    key={index}
                    className='bg-gradient-primary text-light border-end '
                    role="button"
                    onClick={() => handleSort(header)}
                  >
                    {header}
                    {!currentDirection && <RiExpandUpDownFill className="ms-1" />}
                    {currentDirection === 'asc' && <MdArrowDropUp className="ms-1" />}
                    {currentDirection === 'desc' && <MdArrowDropDown className="ms-1" />}
                  </th>
                );
              })}
              <th className={`bg-gradient-primary text-center`}></th>
            </tr>
          </thead>
          <tbody>
            {rows.length > 0 ? (
              rows.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {row.map((cell, cellIndex) => (
                    <td key={cellIndex} className='border-0'>{cell}</td>
                  ))}
                  <td className="text-center border-0">
                    <div className="btn-group">
                      <button type="button" className="btn dropdown-toggle p-0 border-0" data-bs-toggle="dropdown">
                        <BsThreeDotsVertical />
                      </button>
                      <ul className="dropdown-menu dropdown-menu-end">
                        <li>
                          <button className="dropdown-item" data-bs-toggle="modal" data-bs-target="#form-modal" onClick={() => handleView(row[primaryKeyIndex])}>
                            <MdOutlineViewHeadline /> View
                          </button>
                        </li>
                        { permitted === 'true' && (adminOnly === false || role === 'Admin') &&
                          <li>
                            <button className="dropdown-item" data-bs-toggle="modal" data-bs-target="#form-modal" onClick={() => handleEdit(row[primaryKeyIndex])}>
                              <MdOutlineEdit /> Edit
                            </button>
                          </li>
                        }
                        { role === 'Admin' &&
                          <li>
                            <button className="dropdown-item" data-bs-toggle="modal" data-bs-target="#confirm-modal" onClick={() => handleArchiveClick(row[primaryKeyIndex])}>
                              <MdDeleteOutline /> Archive
                            </button>
                          </li>
                        }
                      </ul>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={headers.length + 1} className="text-center">Nothing to show</td>
              </tr>
            )}
          </tbody>
        </table>
      )}

      <ArchiveModal onConfirm={handleArchive} archiveId={archiveId} />
    </div>
  );
};

export default DataTable;
