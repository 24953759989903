import { useEffect, cloneElement, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { FaCheck } from "react-icons/fa6";
import { Modal } from 'bootstrap';
import { useFetchData } from '../../hooks';
import { CalendarToolbar } from '../calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';


const localizer = momentLocalizer(moment);

// FORMAT EVENTS
const EventComponent = ({ event }) => {
  return (
    <div style={{ display: 'col', alignItems: 'center' }}>
      <div>
        <small>{event.resources}</small>
      </div>
      <div>
        <span>{event.title}</span>
        {event.icon && <span style={{ marginLeft: '2rem' }}>{event.icon}</span>} 
      </div>
    </div>
  );
};

const ScheduleCalendar = ({ sessions, setSelectedId, setSelectedEnrollment, setFormState, onMonthChange }) => {
  const [selectedEventId, setSelectedEventId] = useState(null);

  const events = sessions ? sessions.map(session => ({
    id: session.session_id,
    enrollment: session.enrollment,
    status: session.status,
    title: `${session?.transmission_type !== 'NA' ? session?.transmission_type : ''}${session.course}-${session.session_nth}`,
    resources: `${session.instructor_name} / ${session.facility_name}`,
    start: new Date(`${session.session_date}T${session.start_time}`),
    end: new Date(`${session.session_date}T${session.end_time}`),
    icon: session.status === 'Completed' ? <FaCheck /> : null,
  })) : [];

  const { data: enrollmentData, loading, error, refresh } = useFetchData(
    selectedEventId ? `enrollments/${selectedEventId}` : '',
    false
  );

  const handleSelectEvent = (event) => {
    setSelectedEventId(event.enrollment);
    setSelectedId(event.id);
    setFormState('view');

    const modal = new Modal(document.getElementById('form-modal'));
    modal.show();
  };

  useEffect(() => {
    if (enrollmentData) {
      setSelectedEnrollment(enrollmentData);
    }
  }, [enrollmentData, setSelectedEnrollment]);

  // STYLE EVENTS
  const eventStyleGetter = () => ({
    style: {
      backgroundColor: '#FDE3A7',
      border: '3px solid #F8D27F',
      borderRadius: '3px',
      opacity: 1,
      color: 'black',
      fontSize: '0.75rem',
    },
  });

  // SHOW MODAL
  useEffect(() => {
    const modalElement = document.getElementById('form-modal');

    const handleModalClose = () => {
      setSelectedEnrollment(null);
    };

    modalElement.addEventListener('hidden.bs.modal', handleModalClose);

    return () => {
      modalElement.removeEventListener('hidden.bs.modal', handleModalClose);
    };
  }, [setSelectedEnrollment]);

  // STYLE DAY CELL
  const dayPropGetter = (date) => ({
    style: {
      backgroundColor: moment(date).isSame(new Date(), 'day') ? '#FFF7E6' : 'transparent',
    },
  });

  const handleMonthChange = (date) => {
    const newMonth = date.getMonth() + 1;
    const newYear = date.getFullYear();
    onMonthChange(newMonth, newYear);
  };
  

  return (
    <div>
      <Calendar
        localizer={localizer}
        events={events}
        style={{ height: 650 }}
        selectable
        onSelectEvent={handleSelectEvent}
        eventPropGetter={eventStyleGetter}
        dayPropGetter={dayPropGetter}
        components={{
          toolbar: CalendarToolbar,
          event: EventComponent,
        }}
        min={new Date(2020, 0, 23, 7, 0)}
        max={new Date(2050, 11, 31, 19, 0)}
        views={['month', 'day', 'agenda']}
        onNavigate={handleMonthChange}
      />
    </div>
  );
};

export default ScheduleCalendar;
