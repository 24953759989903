import { useState } from "react";

const TextInput = ({ type, label, name, value, onChange, error, disabled, required=true }) => {
  const [emailError, setEmailError] = useState("");
  const [requiredError, setRequiredError] = useState("");

  const handleBlur = () => {
    if (required === true && value === "") {
      setRequiredError("This field is required.");
    } else {
      setRequiredError("");
    }

    if (type === "email" && value) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(value)) {
        setEmailError("Please enter a valid email address.");
      } else {
        setEmailError("");
      }
    }
  };

  return (
    <div className="form-floating mb-3">
      <input
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={handleBlur}
        className={`form-control border-dark ${error || emailError || requiredError ? "is-invalid" : ""}`}
        disabled={disabled}
        required={required}
      />
      <label htmlFor={name} className="text-dark">
        {label}
      </label>
      {(error || emailError || requiredError) && (
        <div className="invalid-feedback">
          {requiredError || emailError || error}
        </div>
      )}
    </div>
  );
};

export default TextInput;
