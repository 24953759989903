import { Routes, Route, Navigate } from 'react-router-dom';
import { AdminStaffLayout, PublicLayout } from '../components/layout';
import { About, CourseDetails, Home, Unauthorized } from './public';
import { Login, Logout, Registration } from './auth';
import { StudentProfile, Schedule } from './student';
import { InstructorProfile, InstructorSchedule } from './instructor';
import { AdminDashboard, AdminStudents, AdminSchedules, Branches, Sessions, Users } from './admin';
import { StaffDashboard, StaffStudents, StaffSchedules } from './staff';
import { Courses, Instructors, Classrooms, Vehicles } from './shared';

const PrivateRoute = ({ children, requiredRole }) => {
  const loginStatus = localStorage.getItem('loginStatus') === "true";
  const userRole = localStorage.getItem('userRole');

  if (!loginStatus) {
    return <Navigate to="/login" />;
  }

  if (requiredRole && userRole !== requiredRole) {
    return <Navigate to="/unauthorized" />;
  }
  return children;
}

const Main = () => {
  return (
    <Routes>
      <Route path="/" element={<PublicLayout />}>
        <Route path="" element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="course-details" element={<CourseDetails />} />
        <Route path="student-profile" element={<PrivateRoute requiredRole="Student">
          <StudentProfile />
        </PrivateRoute>} />
        <Route path="student-schedule" element={<PrivateRoute requiredRole="Student">
          <Schedule />
        </PrivateRoute>} />
        <Route path="instructor-profile" element={<PrivateRoute requiredRole="Instructor">
          <InstructorProfile />
        </PrivateRoute>} />
        <Route path="instructor-schedule" element={<PrivateRoute requiredRole="Instructor">
          <InstructorSchedule />
        </PrivateRoute>} />
      </Route>

      <Route path="login" element={<Login />} />
      <Route path="logout" element={<Logout />} />
      <Route path="register" element={<Registration />} />
      <Route path="unauthorized" element={<Unauthorized />} />

      <Route path="/admin/*" element={<PrivateRoute requiredRole="Admin"> 
        <AdminStaffLayout />
      </PrivateRoute>}>
        <Route index element={<Navigate to="dashboard" replace />} />
        <Route path="dashboard" element={<AdminDashboard />} />
        <Route path="students" element={<AdminStudents />} />
        <Route path="schedules" element={<AdminSchedules />} />
        <Route path="instructors" element={<Instructors />} />
        <Route path="vehicles" element={<Vehicles />} />
        <Route path="classrooms" element={<Classrooms />} />
        <Route path="courses" element={<Courses />} />
        <Route path="branches" element={<Branches />} />
        <Route path="users" element={<Users />} />
        <Route path="sessions" element={<Sessions />} />
      </Route>

      <Route path="/staff/*" element={<PrivateRoute requiredRole="Staff"> 
        <AdminStaffLayout />
      </PrivateRoute>}>
        <Route index element={<Navigate to="dashboard" replace />} />
        <Route path="dashboard" element={<StaffDashboard />} />
        <Route path="students" element={<StaffStudents />} />
        <Route path="schedules" element={<StaffSchedules />} />
        <Route path="instructors" element={<Instructors />} />
        <Route path="vehicles" element={<Vehicles />} />
        <Route path="classrooms" element={<Classrooms />} />
        <Route path="courses" element={<Courses />} />
      </Route>
    </Routes>
  );
}

export default Main;