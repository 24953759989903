import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from "recharts";
import { Alert } from "../other";


const ProgressBar = ({ data, dataKey = "value", maxValue = 100, labelKey = "instructor_name" }) => {
  if (!data || data.length === 0) {
    return <Alert message={"Nothing to show"} />;
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', gap: '20px' }}>
      <ResponsiveContainer width="100%" height={20}>
        <BarChart layout="vertical" data={data} margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
          <XAxis type="number" domain={[0, maxValue]} hide />
          <YAxis type="category" dataKey={labelKey} hide />
          <Tooltip formatter={(value) => [value, 'Utilization Rate']} contentStyle={{ fontSize: '14px' }} />
          <Bar dataKey={dataKey} fill="#F0A500" background={{ fill: "#F8D58A" }} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ProgressBar;
