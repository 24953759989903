import { TextInput, SelectInput, TextAreaInput } from '../input';


const CourseForm = ({ courseData, categories, formState, onChange, onReset, onSave }) => {
  return (
    <form>
      <TextInput 
        type="text"
        label="Course Code" 
        name="course_code" 
        value={courseData.course_code} 
        onChange={onChange} 
        disabled={formState === 'view' || formState === 'edit'}
      />
      <TextInput 
        type="text"
        label="Course Name" 
        name="course_name" 
        value={courseData.course_name} 
        onChange={onChange} 
        disabled={formState === 'view'}
      />
      <SelectInput 
        label="Course Category" 
        name="course_category" 
        value={courseData.course_category} 
        onChange={onChange} 
        options={Array.isArray(categories) ? categories.map(category => ({
          value: category.category_code,
          label: category.category_name,
        })) : []}
        disabled={formState === 'view'}
      />
      <TextAreaInput 
        label="Course Description" 
        name="course_description" 
        value={courseData.course_description} 
        onChange={onChange} 
        disabled={formState === 'view'}
      />
      {formState !== 'new' && (
        <SelectInput 
          label="Status" 
          name="status" 
          value={courseData.status} 
          onChange={onChange} 
          disabled={formState === 'view'}
          options={[
            { label: "Open", value: "Open" },
            { label: "Closed", value: "Closed" },
          ]}
        />
      )}

      <div className="modal-footer pe-0">
        {formState === 'new' && (
          <button onClick={onReset} type="button" className="btn btn-outline-secondary">
            Clear
          </button>
        )}
        {formState !== 'view' && (
          <button onClick={onSave} type="button" className="btn btn-primary" data-bs-dismiss="modal">
            Save
          </button>
        )}
      </div>
    </form>
  );
};

export default CourseForm;
