import { useState, useEffect } from 'react';
import { useDocumentTitle, useFetchData, useCrudHandlers, useLoading } from '../../hooks';
import { StudentForm } from '../../components/form';
import { FormModal } from '../../components/modal';
import { TableHeader, DataTable, Pagination } from '../../components/table';
import { Alert } from '../../components/other';


const Students = ( props ) => {
  useDocumentTitle('Students');
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [filterStatus, setFilterStatus] = useState('');
  const [sortField, setSortField] = useState('enrollment_date');
  const [sortOrder, setSortOrder] = useState('desc');
  const [branch, setBranch] = useState(props.branch)
  const itemsPerPage = 15;
  const loading = useLoading(1000);

  // FETCH DATA with additional query params
  const { data: students, error: fetchStudentsError, totalItems, refresh } = useFetchData(
    `students/?page=${currentPage}&search=${searchQuery}&ordering=${sortOrder === 'asc' ? '' : '-'}${sortField}&branch=${branch}`,
    true, [searchQuery, filterStatus, sortField, sortOrder, currentPage]
  );

  // FETCH DATA for options
  const { data: allBranches, error: fetchAllBranchesError} = useFetchData('branches/');
  const { data: branches, error: fetchBranchesError} = useFetchData('valid-branches/');
  const { data: courses, error: fetchCoursesError} = useFetchData('valid-courses/');

  const initialFormData = { first_name: '', last_name: '', address: '', contact_number: '', emergency_number:'', email: '',
    course: '', transmission_type: '', total_hours: '', preferred_dates: [], branch: '', remarks: '' };

  // CRUD handlers
  const {
    formData, setFormData, selectedId, setSelectedId, formState, setFormState,
    handleSave, handleArchive, handleModalClose, fetchError,
  } = useCrudHandlers('enroll-student/', 'students/', initialFormData, [refresh], refresh);
  
  // TABLE headers and rows
  const headers = ['Student Code', 'First Name', 'Courses Enrolled', 'Year Joined'];
  const rows = students ? students.map(student => [
    student.student_code, student.first_name,
    student.courses_enrolled.map(course => course.course_name).join(', '), student.year_joined, 
  ]) : [];
  
  useEffect(() => {
    if (filterStatus) {
      setBranch(filterStatus);
    } 
  }, [filterStatus]);

  return (
    <div className="custom-container">
      <TableHeader
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        filterOptions={ allBranches ? [{ value: '', label: '...' }, ...allBranches.results.map(branch => ({
          value: branch.branch_name, label: branch.branch_name, }))] : [] }
        setFilterStatus={setFilterStatus}
        setSortField={setSortField}
        setSortOrder={setSortOrder}
        adminOnly={false}
      />
      {fetchStudentsError && <Alert message={fetchStudentsError} />}
      {fetchError && <Alert message={fetchError} />}
      <DataTable
        headers={headers}
        rows={rows}
        loading={loading}
        primaryKeyIndex={0}
        setSelectedId={setSelectedId}
        setFormState={setFormState}
        handleArchive={handleArchive}
        setSortField={setSortField}
        setSortOrder={setSortOrder}
        adminOnly={false}
      />
      <Pagination
        setCurrentPage={setCurrentPage}
        totalItems={totalItems}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
      />
      <FormModal
        title={formState === 'new' ? 'New Student' : formState === 'edit' ? `Edit ${selectedId}` : `${selectedId}`}
        open={selectedId !== null}
        onClose={handleModalClose}
      >
        <StudentForm
          studentData={formData}
          branches={branches}
          courses={courses}
          formState={formState}
          onChange={(e) => setFormData(prevData => ({ ...prevData, [e.target.name]: e.target.value }))}
          onReset={handleModalClose}
          onSave={handleSave}
        />
      </FormModal>
    </div>
  );
};

export default Students;
