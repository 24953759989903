import { useState, useEffect } from 'react';
import { FiEdit } from "react-icons/fi";
import { useDocumentTitle, useResponsiveHeight, useFetchData, usePostData, usePutData } from '../../hooks';
import { ProfileForm } from '../../components/form';
import { ListProgress } from '../../components/other';


const StudentProfile = () => {
  useDocumentTitle("Profile");
  const minHeight = useResponsiveHeight();
  const email = localStorage.getItem('email');

  const [formState, setFormState] = useState("view");
  const [formData, setFormData] = useState({ first_name: '', last_name: '', address: '', contact_number: '', emergency_number: '' });
  const [passwordData, setPasswordData] = useState({ currentPassword: '', newPassword: '' });

  const { data: studentData, error: fetchStudentDataError } = useFetchData(`student-enrollments/${email}`, false);
  const { data: userData, putData, error: updateUserDataError, success: updateSuccess } = usePutData(`users/${email}/`);
  const { postData: changePassword, loading: changePasswordLoading, error: changePasswordError, success: changePasswordSuccess } = usePostData('change-password/');

  const handleChange = (e) => {
    const { name, value } = e.target;
  
    if (name === 'currentPassword' || name === 'newPassword') {
      setPasswordData((prevState) => ({
        ...prevState, [name]: value
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState, [name]: value
      }));
    }
  };

  const handleSave = () => {
    putData({ ...formData, email });
  };

  const handleChangePassword = () => {
    if (passwordData.currentPassword && passwordData.newPassword) {
      const passwordRequestData = {
        email,
        current_password: passwordData.currentPassword,
        new_password: passwordData.newPassword
      };
      changePassword(passwordRequestData);
    }
  };

  const handleCancel = () => {
    setFormData({ first_name: studentData.first_name || '', last_name: studentData.last_name || '' });
    setFormState("view");
  };

  useEffect(() => {
    if (studentData) {
      setFormData({ 
        first_name: studentData.first_name || '', last_name: studentData.last_name || '' , address: studentData.address || '',
        contact_number: studentData.contact_number || '', emergency_number: studentData.emergency_number
      });
    }
  }, [studentData]);

  useEffect(() => {
    if (updateSuccess) {
      setFormState("view");
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (changePasswordSuccess) {
      setFormState("view");
      setPasswordData("");
    }
  }, [changePasswordSuccess]);


  return (
    <div className="d-flex flex-column container p-4" style={{ minHeight: minHeight }}>
      <section className="p-4 text-center border border-4 border-primary rounded-4">
        <div className="row g-5">
          <div className="col-md-5 col-lg-5 order-md-last">
            <h4 className="d-flex justify-content-between align-items-center mb-4">
              <span className="text-secondary">Your Enrollments</span>
              <span className="badge bg-primary rounded-pill">{studentData?.courses_enrolled.length}</span>
            </h4>
            <ListProgress courses={studentData?.courses_enrolled} />
          </div>
          
          <div className="col-md-7 col-lg-7">
            <h4 className="d-flex justify-content-between align-items-center mb-4">
              <span className="text-dark">Your Profile</span>
              {formState === "view" && (
                <button onClick={() => setFormState("edit")} type="button" className="text-primary fs-5 border-0 p-0 bg-transparent me-1">
                  <FiEdit className="text-secondary"/>
                </button>
              )}
            </h4>
            
            <ProfileForm
              userData={studentData}
              passwordData={passwordData}
              formData={formData}
              formState={formState}
              setFormState={setFormState}
              handleChange={handleChange}
              handleSave={handleSave}
              handleCancel={handleCancel}
              handleChangePassword={handleChangePassword}
            />
          </div>
        </div>
      </section>
    </div>
  );
}

export default StudentProfile;
