import MultiDatePicker from 'react-multi-date-picker';

const MarkedDatePicker = ({ label, name, value, preferredDates, onChange, disabled }) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const mapDays = ({ date }) => {
    if (preferredDates.includes(date.format("YYYY-MM-DD"))) {
      return {
        className: `bg-secondary-light text-light`,
      };
    }
  };

  return (
    <div className="form-floating mb-3">
      <MultiDatePicker
        name={name}
        value={value ? new Date(value) : null}
        format="YYYY-MM-DD"
        onChange={onChange}
        mapDays={mapDays}
        inputClass="form-control border-dark"
        style={{
          width: "100%",
          padding: "1.6rem 0.75rem 0.5rem",
          borderRadius: "0.375rem"
        }}
        // disabled={disabled}
        // minDate={today}
      />
      <label
        htmlFor={name}
        className={`text-dark active`}
        style={{
          fontSize: "0.875rem",
          transform: "translateY(-0.5rem)"
        }}
      >{label}</label>
    </div>
  );
};

export default MarkedDatePicker;
