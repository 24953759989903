import { TextInput, PasswordInput, SelectInput } from '../input';

const InstructorForm = ({ instructorData, branches, formState, onChange, onReset, onSave }) => {
  return (
    <form>
      <div className="input-group">
        <TextInput 
          type="text"
          label="First Name" 
          name="first_name" 
          value={instructorData.first_name}
          onChange={onChange} 
          disabled={formState === 'view'}
        />
        <TextInput 
          type="text"
          label="Last Name" 
          name="last_name" 
          value={instructorData.last_name}
          onChange={onChange} 
          required={false}
          disabled={formState === 'view'}
        />
      </div>
      <SelectInput
        label="Senior" 
        name="is_senior" 
        value={instructorData.is_senior} 
        onChange={onChange} 
        options={[
          { label: "Yes", value: true },
          { label: "No", value: false },
        ]}
        disabled={formState === 'view'}
      />
      <SelectInput
        label="Branch" 
        name="branch_name" 
        value={instructorData.branch_name} 
        onChange={onChange} 
        options={ branches ? branches.results.map(branch => ({
          value: branch.branch_name, label: branch.branch_name, })) : [] }
        disabled={formState === 'view'}
      />
      <SelectInput 
        label="Status" 
        name="status" 
        value={instructorData.status} 
        onChange={onChange} 
        options={[
          { label: "Active", value: "Active" },
          { label: "On Leave", value: "On Leave" },
          { label: "Inactive", value: "Inactive" },
        ]}
        disabled={formState === 'view'}
      />

      <hr className='my-3' />
      <h6 className='mb-3'>Account Details <span className='fw-normal'>(optional)</span></h6>
      
      <TextInput 
        type="email"
        label="Email" 
        name="email" 
        value={instructorData?.email} 
        onChange={onChange} 
        required={false}
        disabled={formState === 'view'}
      />

      {formState !== "view" && (
        <>
          <PasswordInput 
            label="Password" 
            name="password" 
            value={instructorData?.password} 
            onChange={onChange} 
            required={instructorData?.email !== ''} // Only required if email is set
            disabled={formState === 'view'}
          />
          <PasswordInput 
            label="Confirm Password" 
            name="re_password" 
            value={instructorData?.re_password} 
            onChange={onChange} 
            required={instructorData?.email !== ''} // Only required if email is set
            disabled={formState === 'view'}
          />
        </>
      )}

      <div className="modal-footer pe-0">
        {formState !== 'view' && (
          <button onClick={onReset} type="button" className="btn btn-outline-secondary">
            Clear
          </button>
        )}
        {formState !== 'view' && (
          <button onClick={onSave} type="button" className="btn btn-primary" data-bs-dismiss="modal">
            Save
          </button>
        )}
      </div>
    </form>
  );
};

export default InstructorForm;
