import { FiEdit } from "react-icons/fi";

const FormModal = ({ title, children, onClose, isLarge=false, setFormState, formState }) => {
  const modalSize = isLarge ? "modal-lg" : "";
  
  const handleEdit = () => {
    setFormState("edit");
  };

  return (
    <div className="modal fade" id="form-modal" tabIndex="-1" aria-hidden="true" data-bs-backdrop="static">
      <div className={`modal-dialog ${modalSize}`}>
        <div className="modal-content container">
          <div className="modal-header">
            <h1 className="modal-title fs-5">{title}</h1>
            <div className="ms-auto">
              {formState === "view" && (
                <button onClick={handleEdit} type="button" className="fs-5 border-0 p-0 bg-transparent me-1">
                  <FiEdit className="text-secondary"/>
                </button>
              )}
              
              <button onClick={onClose} type="button" className="btn-close shadow-none pt-1" data-bs-dismiss="modal" aria-label="Close"> 
              </button>
            </div>
          </div>
          <div className="modal-body text-wrap">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormModal;
