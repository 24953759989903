import { TextInput, PasswordInput, SelectInput } from '../input';


const UserForm = ({ userData, branches, formState, onChange, onReset, onSave }) => {
  return (
    <form>
      <TextInput
        type="email"
        label="Email" 
        name="email" 
        value={userData.email} 
        onChange={onChange} 
        disabled={formState === 'view' || formState === 'edit'} 
      />
      <div className="input-group">
        <TextInput 
          type="text"
          label="First Name" 
          name="first_name" 
          value={userData.first_name} 
          onChange={onChange} 
          disabled={formState === 'view'} 
        />
        <TextInput 
          type="text"
          label="Last Name" 
          name="last_name" 
          required={false}
          value={userData.last_name} 
          onChange={onChange} 
          disabled={formState === 'view'} 
        />
      </div>
      <SelectInput 
        label="Role" 
        name="role" 
        value={userData.role} 
        onChange={onChange} 
        disabled={formState === 'view'} 
        options={[
          { label: "Admin", value: "Admin" },
          { label: "Staff", value: "Staff" },
          { label: "Instructor", value: "Instructor" },
          { label: "Student", value: "Student" },
        ]}
      />
      {formState !== 'view' && formState !== 'edit' && (
        <>
          <PasswordInput
            label="Password" 
            name="password" 
            value={userData.password} 
            onChange={onChange} 
            disabled={formState === 'view'} 
          />
          <PasswordInput
            label="Confirm Password" 
            name="re_password" 
            value={userData.re_password} 
            onChange={onChange} 
            disabled={formState === 'view'} 
          />
        </>
      )}
      {formState !== 'new' && (
        <>
          {userData.role === 'Staff' && (
            <SelectInput 
              label="Branch" 
              name="branch" 
              value={userData.branch} 
              onChange={onChange} 
              disabled={formState === 'view'}
              options={ branches ? branches.results.map(branch => ({
                value: branch.branch_name, label: branch.branch_name, })) : [] }
            />
          )}
          <SelectInput 
            label="Status" 
            name="is_active" 
            value={userData.is_active} 
            onChange={onChange} 
            disabled={formState === 'view'}
            options={[
              { label: "Active", value: true },
              { label: "Deactivated", value: false },
            ]}
          />
          {userData.role === 'Staff' && (
            <SelectInput 
              label="Edit Permissions" 
              name="can_edit" 
              value={userData.can_edit} 
              onChange={onChange} 
              disabled={formState === 'view'}
              options={[
                { label: "Allow", value: true },
                { label: "Deny", value: false },
              ]}
            />
          )}
          <TextInput
            type="text"
            label="Date Joined"
            name="date_joined"
            value={new Date(userData.date_joined).toLocaleDateString()}
            onChange={onChange}
            disabled={formState === 'view' || formState === 'edit'}
          />
        </>
      )}

      <div className="modal-footer">
        {formState === 'new' && (
          <button onClick={onReset} type="button" className="btn btn-outline-secondary">
            Clear
          </button>
        )}
        {formState !== 'view' && (
          <button onClick={onSave} type="button" className="btn btn-primary" data-bs-dismiss="modal">
            Save
          </button>
        )}
      </div>
    </form>
  );
};

export default UserForm;