import { PieChart, Pie, ResponsiveContainer, Cell } from "recharts";
import { Alert } from "../other";


const PieStatic = ({ data, label='Utilized' }) => {
  const centerValue = data.length > 0 ? `${data[0].value}%` : "0";
  const colors = ["#F0A500", "#F8D58A "]

  
  if (!data || data.length === 0) {
    return <Alert message={"Nothing to show"} />;
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', gap: '5px' }}>
      <ResponsiveContainer width="100%" height={200}>
        <PieChart width={400} height={400}>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            innerRadius={50}
            outerRadius={80}
            paddingAngle={5}
            startAngle={90}
            endAngle={-270} 
            fill="#F0A500"
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
            ))}
          </Pie>
          <text x="50%" y="48%" textAnchor="middle" dominantBaseline="middle" fontWeight={"bold"}>
            {centerValue}
          </text>
          <text x="50%" y="57%" textAnchor="middle" dominantBaseline="middle" fontSize={12}>
            {label}
          </text>
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default PieStatic;
