import { Link, NavLink, useLocation } from 'react-router-dom';
import { MdArrowDropDown } from 'react-icons/md';
import logo from '../../assets/images/top_drive_logo.png';

const TopNavbar = ({ textMode, loginStatus, userRole }) => {
  const roleLinks = {
    Admin: [
      { path: '/admin/dashboard', label: 'Admin Page' },
      { path: '/logout', label: 'Log Out' }
    ],
    Staff: [
      { path: '/staff/dashboard', label: 'Staff Page' },
      { path: '/logout', label: 'Log Out' }
    ],
    Instructor: [
      { path: '/instructor-profile', label: 'Profile' },
      { path: '/instructor-schedule', label: 'Schedule' },
      { path: '/logout', label: 'Log Out' }
    ],
    Student: [
      { path: '/student-profile', label: 'Profile' },
      { path: '/student-schedule', label: 'Schedule' },
      { path: '/logout', label: 'Log Out' }
    ]
  };

  const location = useLocation();
  const userLinks = userRole && roleLinks[userRole] ? roleLinks[userRole] : [];
  const isActiveMySpace = userLinks.some(link => location.pathname === link.path);

  return (
    <nav className="py-4 py-lg-5 container">
      <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-between">
        <Link to="/" className="d-flex flex-column flex-lg-row align-items-center mb-2 mb-lg-0 text-white text-decoration-none">
          <img src={logo} alt="Top Drive Logo" width="75" height="75" className="me-lg-3 d-inline-block align-text-center" />
          <h2 className="website-name text-gradient my-2 my-lg-0">TOP DRIVE</h2>
        </Link>

        <ul className="nav col-12 col-lg-auto mb-3 mb-lg-0 justify-content-center mb-md-0">
          <li className="nav-item">
            <NavLink to="/" className={({ isActive }) => `nav-link rounded ${textMode} ${isActive ? 'active' : ''}`}>
              Home
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/about" className={({ isActive }) => `nav-link rounded ${textMode} ${isActive ? 'active' : ''}`}>
              About
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/course-details" className={({ isActive }) => `nav-link rounded ${textMode} ${isActive ? 'active' : ''}`}>
              Courses
            </NavLink>
          </li>

          {!loginStatus && (
            <Link to="login" className="btn btn-primary fw-bold ms-2">
              Log In
            </Link>
          )}

          {loginStatus && roleLinks[userRole] && (
            <li className="btn-group">
              <button
                className={`btn dropdown-toggle border-0 ${textMode} ${isActiveMySpace ? 'fw-bold' : ''}`}
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                My Space <MdArrowDropDown className="p-0 m-0" />
              </button>
              <ul className="dropdown-menu dropdown-menu-end">
                {userLinks.map((link) => (
                  <li key={link.path}>
                    <Link to={link.path} className="dropdown-item">{link.label}</Link>
                  </li>
                ))}
              </ul>
            </li>
          )}
        </ul>
      </div>
    </nav>
  );
};

export default TopNavbar;
