import { FiEdit } from 'react-icons/fi';
import { TextInput, PasswordInput } from "../input";


const ProfileForm = ({ student = false, userData, formData, passwordData, formState, setFormState, 
  handleChange, handleSave, handleCancel, handleChangePassword}) => {
  return (
    <div className="row gx-3">
      {student === true &&
      <div className="col-6">
        <TextInput 
          type="text"
          label="Student Code" 
          name="student_code" 
          value={userData?.student_code}
          disabled={true}
        />
      </div>
      }
      <div className={student === true ? "col-6" : "col-12"}>
        <TextInput 
          type="email"
          label="Email" 
          name="email" 
          value={userData?.email}
          disabled={true}
        />
      </div>
      <div className="col-sm-6">
        <TextInput 
          type="text"
          label="First Name" 
          name="first_name" 
          value={formData?.first_name}
          onChange={handleChange}
          disabled={formState !== "edit"}
        />
      </div>
      <div className="col-sm-6">
        <TextInput 
          type="text"
          label="Last Name" 
          name="last_name" 
          required={false}
          value={formData?.last_name}
          onChange={handleChange}
          disabled={formState !== 'edit'}
        />
      </div>
      {student === true && <>
        <div className="col-12">
          <TextInput 
            type="text"
            label="Address" 
            name="address" 
            value={formData?.address}
            onChange={handleChange}
            disabled={formState !== "edit"}
          />
        </div>
        <div className="col-sm-6">
          <TextInput 
            type="text"
            label="Contact No." 
            name="contact_number" 
            value={formData?.contact_number}
            onChange={handleChange}
            disabled={formState !== "edit"}
          />
        </div>
        <div className="col-sm-6">
          <TextInput 
            type="text"
            label="Emergency Contact No." 
            name="emergency_number" 
            required={false}
            value={formData?.emergency_number}
            onChange={handleChange}
            disabled={formState !== 'edit'}
          />
        </div>
      </> }
      
      {formState === "edit" && (
        <div className='gap-2 d-flex justify-content-end'>
          <button onClick={handleCancel} type="button" className="btn btn-primary">
            Cancel
          </button>
          <button onClick={handleSave} type="button" className="btn btn-dark">
            Save
          </button>
        </div>
      )}
      <hr className='my-4' />
      
      <div className="card p-3 border border-secondary text-start">
        <h5 className="d-flex justify-content-between align-items-center mb-4">
          <span className="text-dark">Your Password</span>
          {formState === "view" && (
            <button onClick={() => setFormState("edit-pw")} type="button" className="text-primary fs-5 border-0 p-0 bg-transparent me-1">
              <FiEdit className="text-dark"/>
            </button>
          )}
        </h5>
        <small>You can reset your password. Please note that your new password should meet the minimal password strength requirements.</small>

        {formState === "edit-pw" && (
          <>
            <div className='row g-3 mt-2'>
              <div className="col-sm-6">
                <PasswordInput
                  label="Current Password" 
                  name="currentPassword" 
                  validateStrength={false}
                  value={passwordData.currentPassword}
                  onChange={handleChange}
                  disabled={formState !== 'edit-pw'}
                />
              </div>

              <div className="col-sm-6">
                <PasswordInput
                  label="New Password" 
                  name="newPassword" 
                  value={passwordData.newPassword}
                  onChange={handleChange}
                  disabled={formState !== 'edit-pw'}
                />
              </div>
            </div>

            <div className='gap-2 d-flex justify-content-end mt-3 mb-2'>
              <button onClick={() => setFormState("view")} type="button" className="btn btn-outline-dark">
                Cancel
              </button>
              <button onClick={handleChangePassword} type="button" className="btn btn-primary">
                Change Password
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default ProfileForm;