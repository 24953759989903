import React from 'react';

function InfoCard({ header, title, description }) {
  return (
    <div className="col">
      <div className="card mx-3 mb-4 rounded-4 shadow-sm border-primary">
        <div className="card-header py-3 text-bg-primary rounded-top-4">
          <h4 className="my-0 fw-normal">{header}</h4>
        </div>
        <div className="card-body">
          <h1 className="card-title">
            {title}
          </h1>
          <p className="mt-3 mb-4">
            {description}
          </p>
        </div>
      </div>
    </div>
  );
}

export default InfoCard;
