import { TextInput, TextAreaInput, SelectInput } from '../input';


const BranchForm = ({ branchData, formState, onChange, onReset, onSave }) => {
  const handleSave = (e) => {
    e.preventDefault();

    const isValid = validateForm(branchData);
    if (!isValid) {
    }
    onSave();
  };

  const validateForm = (data) => {
    if (!data.branch_name || !data.branch_address) {
      return false;
    }
    return true;
  };

  return (
    <form onSubmit={handleSave}>
      <TextInput 
        type="text"
        label="Branch Name" 
        name="branch_name" 
        value={branchData.branch_name} 
        onChange={onChange} 
        disabled={formState === 'view' || formState === 'edit'} 
      />
      <TextAreaInput 
        label="Branch Address" 
        name="branch_address" 
        value={branchData.branch_address} 
        onChange={onChange} 
        disabled={formState === 'view'}
      />
      <SelectInput 
        label="Status" 
        name="status" 
        value={branchData.status} 
        onChange={onChange} 
        disabled={formState === 'view'}
        options={[
          { label: "Open", value: "Open" },
          { label: "Closed", value: "Closed" },
        ]}
      />

      <div className="modal-footer pe-0">
        {formState === 'new' ? 
          <button onClick={onReset} type="button" className="btn btn-outline-secondary">
            Clear
          </button> : null
        }
        {formState !== 'view' ? 
          <button type="submit" className="btn btn-primary">
            Save
          </button> : null
        }
      </div>
    </form>
  );
};

export default BranchForm;
