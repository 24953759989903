import { Link, useLocation } from 'react-router-dom';
import logo from '../../assets/images/top_drive_logo.png';


const SidebarItem = ({ to, icon, label, isActive }) => (
  <Link to={to} className={`sidebar-link d-flex align-items-center px-5 py-3 ${isActive ? 'active' : ''}`}>
    {icon} {label}
  </Link>
);

const Sidebar = ({ links, logoutLink = '/logout' }) => {
  const { pathname } = useLocation();

  const getPathSegment = () => {
    const segments = pathname.split('/').filter(Boolean);
    return segments.length > 1 ? segments[1] : '';
  };
  
  const currentSegment = getPathSegment();

  const isActive = (segment) => currentSegment === segment;

  return (
    <div className='sidebar d-flex flex-column pt-4 rounded-4 hide-scrollbar bg-gradient-primary shadow'>
      <div className='d-flex justify-content-center mb-3'>
        <Link to='/'><img src={logo} alt='Logo' className='img-fluid' style={{ maxHeight: '70px' }} /></Link>
      </div>

      <nav className='nav flex-column mt-2'>
        {links.map((link) => (
          <SidebarItem 
            key={link.to} 
            to={link.to} 
            icon={link.icon} 
            label={link.label} 
            isActive={isActive(link.to.split('/')[2])} 
          />
        ))}
      </nav>

      <div className='logout-wrapper p-4 mt-auto'>
        <div className='logout-box p-4 d-flex justify-content-center align-items-center rounded-3 bg-gradient-secondary'>
          <Link to={logoutLink} className="btn btn-dark w-100">Log Out</Link>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
