import { TextInput, SelectInput } from '../input';


const ClassroomForm = ({ classroomData, branches, formState, onChange, onReset, onSave }) => {
  return (
    <form>
      <div className="input-group">
        <SelectInput
          label="Branch" 
          name="branch" 
          value={classroomData.branch} 
          onChange={onChange} 
          options={ branches ? branches.results.map(branch => ({
            value: branch.branch_name, label: branch.branch_name, })) : [] }
          disabled={formState === 'view'}
        />
        <TextInput 
          type="number"
          label="Capacity" 
          name="capacity" 
          value={classroomData.capacity} 
          onChange={onChange} 
          disabled={formState === 'view'}
        />
      </div>
      <SelectInput 
        label="Status" 
        name="status" 
        value={classroomData.status} 
        onChange={onChange} 
        options={[
          { label: "Available", value: "Available" },
          { label: "Unavailable", value: "Unavailable" },
        ]}
        disabled={formState === 'view'}
      />

      <div className="modal-footer pe-0">
        {formState !== 'view' && (
          <button onClick={onReset} type="button" className="btn btn-outline-secondary">
            Clear
          </button>
        )}
        {formState !== 'view' && (
          <button onClick={onSave} type="button" className="btn btn-primary" data-bs-dismiss="modal">
            Save
          </button>
        )}
      </div>
    </form>
  );
};

export default ClassroomForm;
