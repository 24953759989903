import { Link } from "react-router-dom";
import { PiProhibitFill } from "react-icons/pi";

const Unauthorized = () => {
  const userRole = localStorage.getItem("userRole");

  let redirectLink = "/";
  let redirectLabel = "Home Page";

  switch (userRole) {
    case "Admin":
      redirectLink = "/admin";
      redirectLabel = "Admin Dashboard";
      break;
    case "Staff":
      redirectLink = "/staff";
      redirectLabel = "Staff Dashboard";
      break;
    default:
      redirectLink = "/";
      redirectLabel = "Home Page";
      break;
  }

  return (
    <div className="d-flex justify-content-center align-items-center m-auto text-center min-vh-100 p-5">
      <div className="custom-container bg-primary-light shadow-lg p-4">
        <h1 className="display-2 text-secondary d-flex align-items-center justify-content-center">
          4<PiProhibitFill className="align-items-center display-2 p-0" />3
        </h1>
        <h2 className="mb-3">ACCESS DENIED</h2>
        <small>We are sorry, but you do not have the permissions to access this resource.</small>
        <br />
        <small>Ensure you are logged in with the appropriate credentials.</small>
        <br />
        <Link to={redirectLink} className="btn btn-sm btn-dark fw-bold mt-4">
          Go to {redirectLabel}
        </Link>
      </div>
    </div>
  );
};

export default Unauthorized;
