import { useState } from 'react';
import api from '../api';

const usePutData = (url) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const putData = async (putData) => {
    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      const response = await api.put(url, putData);
      setData(response.data);
      setSuccess(true);
    } catch (err) {
      if (err.response && err.response.data) {
        setError(err.response.data.error || 'Something went wrong.');
      } else {
        setError(err.message || 'An unknown error occurred.');
      }
    } finally {
      setLoading(false);
    }
  };

  return { data, putData, loading, error, success };
};

export default usePutData;