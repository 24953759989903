import { TextInput, SelectInput } from '../input';


const VehicleForm = ({ vehicleData, branches, formState, onChange, onReset, onSave }) => {
  return (
    <form>
      <div className="input-group">
        <SelectInput 
          label="Wheel Number" 
          name="wheel_num" 
          value={vehicleData.wheel_num} 
          onChange={onChange} 
          options={[
            { label: "2 Wheels", value: "2W" },
            { label: "3 Wheels", value: "3W" },
            { label: "4 Wheels", value: "4W" }
          ]} 
          disabled={formState === 'view'}
        />
        <SelectInput 
          label="Transmission Type" 
          name="transmission_type" 
          value={vehicleData.transmission_type} 
          onChange={onChange} 
          options={[
            { label: "Automatic", value: "AT" },
            { label: "Manual", value: "MT" },
          ]} 
          disabled={formState === 'view'}
        />
      </div>

      <div className="input-group">
        <TextInput 
          type="text"
          label="Manufacturer" 
          name="manufacturer" 
          value={vehicleData.manufacturer} 
          onChange={onChange} 
          disabled={formState === 'view'}
        />
        <TextInput 
          type="text"
          label="Model" 
          name="vehicle_model" 
          value={vehicleData.vehicle_model} 
          onChange={onChange} 
          disabled={formState === 'view'}
        />
        <TextInput 
          type="text"
          label="Color" 
          name="color" 
          value={vehicleData.color} 
          onChange={onChange} 
          disabled={formState === 'view'}
        />
      </div>

      <div className="input-group">
        <SelectInput
          label="Branch" 
          name="branch" 
          value={vehicleData.branch} 
          onChange={onChange} 
          options={ branches ? branches.results.map(branch => ({
            value: branch.branch_name, label: branch.branch_name, })) : [] }
          disabled={formState === 'view'}
        />
        <SelectInput 
          label="Status" 
          name="status" 
          value={vehicleData.status} 
          onChange={onChange} 
          options={[
            { label: "Available", value: "Available" },
            { label: "Unavailable", value: "Unavailable" },
          ]}
          disabled={formState === 'view'}
        />
      </div>

      <div className="modal-footer pe-0">
        {formState !== 'view' && (
          <button onClick={onReset} type="button" className="btn btn-outline-secondary">
            Clear
          </button>
        )}
        {formState !== 'view' && (
          <button onClick={onSave} type="button" className="btn btn-primary" data-bs-dismiss="modal">
            Save
          </button>
        )}
      </div>
    </form>
  );
};

export default VehicleForm;
