import React from 'react';
import DatePicker from 'react-multi-date-picker';
import { MdEditCalendar, MdOutlineClose } from "react-icons/md";

const DatePickerInput = ({ name, value, onChange, disabledDates, disabled }) => {
  const dateObjects = value.map(date => (typeof date === 'string' ? new Date(date) : date));

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const handleDateRemove = (dateToRemove) => {
    const newDates = dateObjects.filter((date) => date.getTime() !== dateToRemove.getTime());
    onChange({ target: { name, value: newDates.map(date => date.toISOString().split('T')[0]) } });
  };

  return (
    <div className="mb-3">
      <label htmlFor={name} className="form-text text-dark mb-2">
        Select preferred dates. Leave blank for anytime.
      </label>
      <div className="d-flex justify-content-between align-items-center">
        {disabled ? (
          <div className="form-control align-items-center bg-secondary text-light">
            <span htmlFor={name} className="form-text text-light">
              SELECT DATE
            </span>
            <MdEditCalendar className='ms-2 fs-5'/>
          </div>
        ) : (
          <DatePicker
            multiple
            value={dateObjects}
            onChange={(dates) => {
              const formattedDates = dates.map(date => date.format('YYYY-MM-DD'));
              onChange({ target: { name, value: formattedDates } });
            }}
            disabledDates={disabledDates}
            minDate={today} 
            render={(value, openCalendar) => (
              <div className="form-control align-items-center bg-primary text-light" onClick={openCalendar}>
                <span htmlFor={name} className="form-text text-light">
                  SELECT DATE
                </span>
                <MdEditCalendar className='ms-2 fs-5'/>
              </div>
            )}
          />
        )}
      </div>
      <div className="d-flex justify-content-center">
        {dateObjects.length > 0 && (
          <div className="container selected-dates row row-cols justify-content-evenly align-items-center">
            {dateObjects.map((date, index) => (
              <div key={index} className="badge rounded border border-primary bg-light text-dark mt-2" style={{width: "30%"}}>
                {date.toISOString().split('T')[0]} {/* Format to YYYY-MM-DD */}
                <MdOutlineClose onClick={() => handleDateRemove(date)} className='ms-1 fs-6 text-primary' />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default DatePickerInput;
