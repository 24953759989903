import { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { useDocumentTitle } from '../../hooks';
import { TextInput, SelectInput, PasswordInput } from '../../components/input';
import { Alert } from '../../components/other';
import logo from '../../assets/images/top_drive_logo.png';
import api from '../../api';

const Registration = () => {
  useDocumentTitle("Registration");
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const initialRegistrationData = {
    email: '', first_name: '', last_name: '', password: '', re_password: '', student_code: '',
    address: '', contact_number: '', emergency_number: ''
  };

  const [registrationData, setRegistrationData] = useState(initialRegistrationData);

  const handleChange = (e) => {
    setRegistrationData({
      ...registrationData, [e.target.name]: e.target.value
    });
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage('');

    if (registrationData.password !== registrationData.re_password) {
      setErrorMessage("Passwords do not match");
      return;
    }

    try {
      const res = await api.post('register/', registrationData);
      if (res.data.success) {
        navigate('/login');
      } else {
        setErrorMessage(res.data.error || 'An unexpected error occurred. Please try again.');
      }
    } catch (error) {
      console.error(error);
      setErrorMessage('An unexpected error occurred. Please try again.');
    }
  };

  return (
    <div className="min-vh-100 justify-content-center align-items-center">
      <div className=" d-flex justify-content-center align-items-center">
        <div className="row w-100">
          {/* LOGO SECTION */}
          <div className="col-md-6 d-flex flex-column justify-content-between p-5 bg-dark text-center rounded-top-sm rounded-start-md shadow">
            <div className='my-auto'>
              <Link to="/" className="text-decoration-none">
                <img
                  src={logo}
                  alt="Top Drive Driving School Logo"
                  className="img-fluid mt-1 mt-md-5 mb-3 w-50 w-md-25"
                  height="200"
                />
                <h1 className="d-none d-md-block website-name text-gradient mb-2">TOP DRIVE</h1>
              </Link>
              <p className="d-none d-md-block fw-bold text-decoration-none text-primary mb-5">
                YOUR DRIVING <span className="text-light">101</span> MASTERS
              </p>
            </div>
            <small className="d-none d-md-block text-light">&copy; All rights reserved. Top Drive Driving Institute.</small>
          </div>

          {/* REGISTRATION FORM SECTION */}
          <div className="col-md-6 d-flex flex-column justify-content-between p-5 bg-light rounded-bottom-sm rounded-end-md shadow">
            <div className="text-center mb-5">
              <h1 className="display-3">Hello!</h1>
              <small>Fill in with your details to register.</small>
            </div>
            
            <form onSubmit={handleSubmit} className="d-flex flex-column w-100 registration-form">
              <TextInput
                type="text"
                label="Student Code"
                name="student_code"
                value={registrationData.student_code}
                onChange={handleChange}
              />
              <div className="input-group">
                <TextInput 
                  type="text"
                  label="First Name" 
                  name="first_name" 
                  value={registrationData.first_name}
                  onChange={handleChange} 
                />
                <TextInput 
                  type="text"
                  label="Last Name" 
                  name="last_name" 
                  value={registrationData.last_name}
                  onChange={handleChange} 
                />
              </div>
              <TextInput 
                type="text"
                label="Address" 
                name="address" 
                value={registrationData.address}
                onChange={handleChange} 
              />
              <div className="input-group">
                <TextInput 
                  type="text"
                  label="Contact No." 
                  name="contact_number" 
                  value={registrationData.contact_number}
                  onChange={handleChange} 
                />
                <TextInput 
                  type="text"
                  label="Emergency Contact No." 
                  name="emergency_number" 
                  value={registrationData.emergency_number}
                  onChange={handleChange}
                />
              </div>
              <TextInput 
                type="email"
                label="Email" 
                name="email" 
                value={registrationData.email}
                onChange={handleChange} 
              />
              <PasswordInput
                label="Password"
                name="password"
                value={registrationData.password}
                onChange={handleChange}
              />
              <PasswordInput
                label="Confirm Password"
                name="re_password"
                value={registrationData.re_password}
                onChange={handleChange}
              />

              {errorMessage && ( <Alert message={errorMessage} /> )}

              <button type="submit" className="btn btn-primary my-5 w-100 fw-bold">Register</button>
              <small className="mb-0 text-center">Already have an account? <Link to="/login" className="fw-bold link-secondary">Log In!</Link></small>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Registration;
