import { useState } from 'react';
import { useDocumentTitle } from '../../hooks';
import { Link } from 'react-router-dom';
import { PasswordInput, TextInput } from '../../components/input';
import { Alert } from '../../components/other';
import logo from '../../assets/images/top_drive_logo.png';
import api from '../../api';

const Login = () => {
  useDocumentTitle("Login");
  const [errorMessage, setErrorMessage] = useState('');
  const [loginData, setLoginData] = useState({
    email: '', password: ''
  });
  
  const handleChange = (e) => {
    setLoginData({
      ...loginData, [e.target.name]: e.target.value
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');
  
    try {
      const res = await api.post('login/', loginData);
      if (res.data.bool === true) {
        localStorage.setItem('loginStatus', 'true');
        localStorage.setItem('email', res.data.email);
        localStorage.setItem('userRole', res.data.role);
        localStorage.setItem('canEdit', res.data.can_edit);
        localStorage.setItem('branch', res.data.branch);
  
        const role = res.data.role;
        window.location.href = role === 'Admin' ? '/admin/dashboard' :
                               role === 'Staff' ? '/staff/dashboard' : '/';
      } else {
        setErrorMessage(res.data.error);
      }
    } catch (error) {
      console.error(error);
      setErrorMessage('An unexpected error occurred. Please try again.');
    }
  }
  
  return (
    <div className="min-vh-100 d-flex justify-content-center align-items-center">
      <div className="container h-100 d-flex justify-content-center align-items-center">
        <div className="row w-100 px-3 py-4">
          {/* LOGO SECTION */}
          <div className="col-md-6 d-flex flex-column justify-content-between p-5 bg-dark text-center rounded-top-sm rounded-start-md shadow">
            <div className='my-auto'>
              <Link to="/" className="text-decoration-none">
                <img
                  src={logo}
                  alt="Top Drive Driving School Logo"
                  className="img-fluid mt-1 mt-md-5 mb-3 w-50 w-md-25"
                  height="200"
                />
                <h1 className="d-none d-md-block website-name text-gradient mb-2">TOP DRIVE</h1>
              </Link>
              <p className="d-none d-md-block fw-bold text-decoration-none text-primary mb-5">
                YOUR DRIVING <span className="text-light">101</span> MASTERS
              </p>
            </div>
            <small className="d-none d-md-block text-light">&copy; All rights reserved. Top Drive Driving Institute.</small>
          </div>

          {/* LOGIN FORM SECTION */}
          <div className="col-md-6 d-flex flex-column justify-content-between p-5 bg-light rounded-bottom-sm rounded-end-md shadow">
            <div className="text-center mb-5">
              <h1 className='display-3'>Hello!</h1>
              <small>Enter your credentials to log in.</small>
            </div>

            <form onSubmit={handleSubmit} className="d-flex flex-column w-100">
              <TextInput
                type="email"
                label="Email"
                name="email"
                value={loginData.email}
                onChange={handleChange}
              />
              <PasswordInput
                label="Password"
                name="password"
                value={loginData.password}
                onChange={handleChange}
                validateStrength={false}
              />

              {errorMessage && <Alert message={errorMessage} />}

              <button type="submit" className="btn btn-primary w-100 my-5 fw-bold">Log In</button>
              <small className="mb-0 text-center">Don't have an account yet? <Link to="/register" className="fw-bold link-secondary">Register!</Link></small>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
