import { useState } from "react";

const SelectInput = ({ label, name, value, onChange, options, error, disabled, required = true }) => {
  const [requiredError, setRequiredError] = useState("");

  const handleBlur = () => {
    if (required && !value) {
      setRequiredError("This field is required.");
    } else {
      setRequiredError("");
    }
  };

  return (
    <div className="form-floating mb-3">
      <select
        className={`form-control border-dark ${error || requiredError ? "is-invalid" : ""}`}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={handleBlur}
        disabled={disabled}
        required={required}
      >
        <option value="">...</option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <label htmlFor={name} className="text-dark">
        {label}
      </label>
      {(error || requiredError) && (
        <div className="invalid-feedback">{requiredError || error}</div>
      )}
    </div>
  );
};

export default SelectInput;
