import { useState } from 'react';
import { FaMapMarkerAlt } from "react-icons/fa";
import { useDocumentTitle, useFetchData } from '../../hooks';


const About = () => {
  useDocumentTitle("About Us");

  const [activeSection, setActiveSection] = useState('VISION');

  const handleNavClick = (section, event) => {
    event.preventDefault();
    setActiveSection(section);
  };

  const { data: branches, error: fetchBranchesError} = useFetchData('valid-branches/');

  
  return (
    <div className="d-flex flex-column">
      <div className="container p-4">
        {/* HEADER */}
        <section className="p-4 text-center border border-4 border-primary rounded-4">
          <div className="col-md-6 p-lg-5 mx-auto text-dark">
            <h1 className="display-3 text-dark">Top Drive Driving Institute</h1>
            <br/>
            <p className="text-body-secondary">
              A premier driving school committed to providing a comprehensive theoretical and practical driving program for student drivers. We focus on essential skills such as defensive driving, traffic laws, and safe maneuvering techniques.
            </p>
          </div>
        </section>

        {/* MISSION VISION */}
        <nav className="nav nav-pills nav-fill my-4">
          {['MISSION', 'VISION'].map((section) => (
            <a key={section} className={`nav-link ${activeSection === section ? 'active bg-primary text-light' : 'text-dark'}`}
              href="#" onClick={(event) => handleNavClick(section, event)}>
              {section}
            </a>
          ))}
        </nav>
        <hr/>
        {activeSection === 'MISSION' && (
          <div className="pricing-header p-3 mx-auto text-center">
            <p className="text-dark">
              Our mission is to provide our valued students a solid foundation of knowledge and skills to help them become safe, confident, and responsible drivers.
            </p>
          </div>
        )}
        {activeSection === 'VISION' && (
          <div className="pricing-header p-3 mx-auto text-center">
            <p className="text-dark">
              Our vision is to inspire our students to be responsible and safe drivers by providing the best innovation and techniques we can offer. With these services, we aim to become the most beloved and driven driving institute.
            </p>
          </div>
        )}
        <hr />

        {/* BRANCHES */}
        <section className="d-md-flex flex-md-equal w-100 my-4 border border-4 border-dark rounded-4">
          <article className="text-bg-dark text-center py-4 px-lg-5">
            <div className="my-3 py-3">
              <h2 className="display-5">OUR BRANCHES</h2>
              <p className="text-white-50">Drive with us from our five branches across Batangas!</p>
            </div>
            <div className="bg-body-tertiary shadow-sm mx-auto text-dark" style={{ width: '80%', height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '21px 21px 0 0' }}>
              <FaMapMarkerAlt size={100} className="text-primary" />
            </div>
          </article>

          <div className="container col">
            <div className="row row-cols-1 row-cols-sm-2 text-dark g-0">
              {branches?.results.map((branch, index) => (
                <div className="col d-flex flex-column gap-3 p-4" key={index}>
                  <h4 className="fw-semibold">{branch.branch_name}</h4>
                  <p>{branch.branch_address}</p>
                </div>
              ))}
            </div>
          </div>
        </section>

      </div>
    </div>
  );
}

export default About;
